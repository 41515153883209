import URLs from "../config/environment";

export const LoginUrl = {
  LOG_IN: URLs?.apiUrls?.user + "/user/authenticate",
  REFRESH_TOKEN: URLs?.apiUrls?.user + "/user/refresh-token",
  LOGOUT: URLs?.apiUrls?.user + "/user/logout",
  FORGET_PASSWORD: URLs?.apiUrls?.user + "/user/password",
  RESET_PASSWORD: URLs?.apiUrls?.user + "/user/password/reset",
};

export const UserUrl = {
  CURRENT_USER: URLs?.apiUrls?.user + "/session/user",
  USER: URLs?.apiUrls?.user + "/user",
  // USER_DASHBOARD: URLs?.apiUrls?.user + "/dashboard",
  USER_DASHBOARD: URLs?.apiUrls?.user + "/user-summary",
  USER_STATE: URLs?.apiUrls?.user + "/notification/privacy/state",
  TICKET: URLs?.apiUrls?.user + "/ticket",
  TICKET_RECEIPE: URLs?.apiUrls?.recipe + "/ticket",
  // USER_ANALYTICS: URLs?.apiUrls?.recipe + "/post/post-analytics",
  USER_ANALYTICS: URLs?.apiUrls?.recipe + "/post-analytics",
  ATTACHMENT: URLs?.apiUrls.attachment + "/attachment",
  CONFIRM_EMAIL: URLs?.apiUrls.user + "/confirm-email",
  NOTIFICATION_COUNT: URLs?.apiUrls.recipe + "/ticket/count",
};

export const RecipeUrl = {
  TICKET: URLs?.apiUrls?.recipe + "/ticket",
  Base: URLs?.apiUrls?.recipe,
  RecipeAnalytics: URLs?.apiUrls?.recipe + "/user",
  Recipe: URLs?.apiUrls?.recipe + "/recipe",
  RecipeAdmin: URLs?.apiUrls?.recipe + "/recipe-admin",
};

export const AdminVendorURL = {
  ADMIN_VENDOR_LIST: URLs.apiUrl + "/api/v1/vendor-list",
  ADMIN_VENDOR_STATUS: URLs.apiUrl + "/api/v1/vendor-status-update",
  ADMIN_VENDOR_DELETE: URLs.apiUrl + "/api/v1/vendor-delete",
  ADMIN_VENDOR_CREATE_OR_UPDATE: URLs.apiUrl + "/api/v1/vendor-register",
};

export const NotificationUrl = {
  NOTIFY_URL: URLs.apiUrl + "/api/v1/notification",
  NOTIFY_URL_API: URLs.apiUrl + "/api/v1/notification/",
};
