import { Pie } from "react-chartjs-2";

function PieChart({ udata }) {
  let title = udata?.title;
  let icon = udata?.icon;

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 2,
    borderSkipped: true,
    plugins: {
      beginAtZero: true,
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="bg-white drop-shadow-dashboard-chart rounded-md shadow p-5">
      <div className="text-sm text-gray-600 mb-3 font-Biko inline-flex justify-center">
        <img src={icon} className="mr-1" />
        {title}
      </div>
      <div className="">
        <Pie
          datasetIdKey={Math.floor(Math.random() * 100)}
          data={udata?.data}
          options={options}
          redraw={false}
        />
      </div>
    </div>
  );
}

export default PieChart;
