import React, { Fragment, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, Transition } from "@headlessui/react";
import Modal from "@shared/component/modal/modal";
import Table from "@shared/component/table/table"; // new
import Toggle from "@shared/component/toggle";
import TextAreaField from "@shared/component/textarea-field";
import ChangeStatus from "@shared/component/change-status";
import moment from "moment";

const SearchFields = ({ ticketQueryData, setTicketQueryData }) => {
  const { t } = useTranslation();
  const [seacrhData, setSeacrhData] = useState({});

  return (
    <>
      <div className="flex items-center gap-6 mb-5">
        <div className="flex flex-wrap items-center flex-1 gap-6">
          <div className="flex-item flex-1">
            <input
              type="date"
              className="py-2.5 text-sm text-gray-500 bg-white border-primary rounded-md w-full max-w-full focus:outline-none focus:ring-primary focus:border-primary"
              placeholder="Date"
              name="ticketId"
              value={seacrhData?.date}
              onChange={(e) => {
                if (e.target.value === "") {
                  return setSeacrhData({
                    ...seacrhData,
                    date: null,
                  });
                }
                setSeacrhData({
                  ...seacrhData,
                  date: e.target.value,
                });
              }}
              autoComplete="off"
            />
          </div>
          <div>
            <input
              type="text"
              className="py-2.5 text-sm text-gray-500 bg-white border-primary rounded-md w-full max-w-full focus:outline-none focus:ring-primary focus:border-primary"
              placeholder="User Name"
              value={seacrhData?.userName || ""}
              onChange={(e) => {
                setSeacrhData({
                  ...seacrhData,
                  userName: e.target.value,
                });
              }}
              autoComplete="off"
            />
          </div>
          <div className="flex-item flex-1">
            <input
              type="text"
              className="py-2.5 text-sm text-gray-500 bg-white border-primary rounded-md w-full max-w-full focus:outline-none focus:ring-primary focus:border-primary"
              placeholder="Ticket ID"
              name="ticketId"
              value={seacrhData?.ticketId}
              onChange={(e) =>
                setSeacrhData({
                  ...seacrhData,
                  ticketId: e.target.value,
                })
              }
              autoComplete="off"
            />
          </div>
          <div className="flex-item flex-1">
            <input
              type="text"
              className="py-2.5 text-sm text-gray-500 bg-white border-primary rounded-md w-full max-w-full focus:outline-none focus:ring-primary focus:border-primary"
              placeholder="Description"
              name="description"
              value={seacrhData?.description}
              onChange={(e) =>
                setSeacrhData({
                  ...seacrhData,
                  description: e.target.value,
                })
              }
              autoComplete="off"
            />
          </div>
          <div className="flex-item flex-1">
            <select
              name="ticketStatus"
              className="py-2.5 text-sm text-gray-500 bg-white border-primary rounded-md w-full focus:outline-none focus:ring-primary focus:border-primary"
              value={seacrhData?.ticketStatus}
              onChange={(e) =>
                setSeacrhData({
                  ...seacrhData,
                  ticketStatus: e.target.value,
                })
              }
            >
              <option value="">{t(`COMMON.SELECT_STATUS`)}</option>
              <option value="OPEN">{t(`COMMON.OPEN`)}</option>
              <option value="CLOSED">{t(`COMMON.CLOSED`)}</option>
            </select>
          </div>
        </div>
        <div className="shrink-0 flex gap-4">
          <button
            className={`inline-flex justify-center float-right rounded-md border-transparent bg-gradient-primary px-10 py-2 text-lg text-white focus:outline-none disabled:opacity-25 disabled:cursor-not-allowed`}
            onClick={() => {
              setTicketQueryData({
                ...ticketQueryData,
                ...seacrhData,
                isFilter: true,
                isSearched: true,
              });
            }}
          >
            {t(`COMMON.SEARCH`)}
          </button>
          <button
            className={`inline-flex justify-center float-right rounded-md border-transparent bg-gradient-primary px-10 py-2 text-lg text-white focus:outline-none disabled:opacity-25 disabled:cursor-not-allowed`}
            onClick={() => {
              setTicketQueryData({
                ...ticketQueryData,
                ticketId: null,
                title: null,
                ticketStatus: null,
                description: null,
                date: null,
                userName: null,
                isFilter: true,
                isSearched: true,
              });
              setSeacrhData({
                ...seacrhData,
                date: "",
                userName: null,
                description: null,
                ticketId: null,
                title: null,
                ticketStatus: null,
              });
            }}
          >
            {t(`COMMON.CLEAR`)}
          </button>
        </div>
      </div>
    </>
  );
};

const TicketsPresentational = ({
  isTicketListEmpty,
  ticketQueryData,
  setTicketQueryData,
  ticketListData,
  updateTicketStatus,
  selectedTicket,
  setSelectedTicket,
  ticketDate,
  setTicketDate,
  ticketDescription,
  setTicketDescription,
  ticketStatus,
  setTicketStatus,
  ticketId,
  setTicketId,
  updateTicketNotes,
  btnDisable,
  setBtnDisable,
  showTicketModal,
  setShowTicketModal,
  openTicket,
  ticketData,
  updateTicket,
  ticketUpdates,
  setTicketUpdates,
  ticketUpdateTxt,
  setTicketUpdateTxt,
  clearTempData,
}) => {
  const { t } = useTranslation();

  const columns = React.useMemo(
    () => [
      {
        Header: t(`COMMON.SNO`),
        accessor: "sno",
        disableSortBy: true,
      },
      {
        Header: t(`COMMON.DATE`),
        accessor: "date",
        render: () => {
          <span>12</span>;
        },
      },
      {
        Header: t(`COMMON.TICKET_ID`),
        accessor: "ticketId",
      },
      {
        Header: t(`COMMON.USER_NAME`),
        accessor: "userName",
      },
      // {
      //   Header: t(`COMMON.TITLE`),
      //   accessor: "title",
      // },
      {
        Header: t(`COMMON.DESCRIPTION`),
        accessor: "description",
      },
      {
        Header: t(`COMMON.STATUS`),
        accessor: "status",
      },
      // {
      //   Header: t(`COMMON.DELETE`),
      //   accessor: "delete",
      //   disableSortBy: true,
      // },
    ],
    [t]
  );

  const getSelectedTicketValues = (id, date, description, status) => {
    setSelectedTicket(1);
    setTicketId(id);
    setTicketDate(date);
    setTicketDescription(description);
    setTicketStatus(status);
    if (status === "CLOSED") {
      setBtnDisable(true);
    }
  };

  const getData = useCallback(() => {
    const snoBase = ticketListData?.page?.number * ticketListData?.page?.size;
    const ticketList = ticketListData?.content?.map(function (ticket, index) {
      const sno = snoBase + index + 1;
      const ticketState =
        ticket?.state?.toLowerCase() === "open" ? true : false;
      // const utcDateTime = new Date("2023-08-11T17:35:19.695Z");
      const createdAt = moment(ticket?.date).format("MM / DD / YYYY hh:mm A");

      return {
        sno: sno,
        date: createdAt,
        ticketId: (
          <>
            <button
              className="text-primary"
              onClick={() => {
                openTicket(ticket);
              }}
            >
              {ticket?.ticketId}
            </button>
          </>
        ),
        userName: ticket?.userName,
        title: <button onClick={() => {}}>{ticket?.title}</button>,
        description: <span>{ticket?.description}</span>,
        status: (
          // <ChangeStatus
          //   lebel={ticket?.ticketStatus}
          //   id={ticket?.id}
          //   checked={ticketState}
          //   updateTicketStatus={updateTicketStatus}
          // />

          <>
            <select
              className={`bg-[#F5F5F5] border-none focus:outline-none focus:ring-0 ${
                ticket?.ticketStatus === "OPEN"
                  ? "text-red-600"
                  : "text-green-600"
              }`}
              onChange={(e) => {
                updateTicketStatus(ticket?.id, e.target.value);
              }}
              value={ticket?.ticketStatus}
            >
              <option value="OPEN">Open</option>
              <option value="CLOSED">Closed</option>
            </select>
          </>
        ),
        // delete: (
        //   <DeletePopup
        //     onClickConfirm={() => {
        //       deleteUser(user?.userId);
        //     }}
        //   />
        // ),
      };
    });
    return ticketList;
  }, [ticketListData]);

  const handleSorting = useCallback((sortData) => {
    let sort = "ticket.createdAt,desc";
    if (sortData?.length > 0) {
      const sortKeyId = sortData?.[0]?.id;
      sort = sortData?.[0]?.desc ? "desc" : "asc";
      let sortKey = "";
      switch (sortKeyId) {
        case "date": {
          sortKey = "ticket.createdAt";
          break;
        }
        case "ticketId": {
          sortKey = "ticket.ticketId";
          break;
        }
        case "userName": {
          sortKey = "ticket.userName";
          break;
        }
        case "title": {
          sortKey = "ticket.title";
          break;
        }
        case "description": {
          sortKey = "ticket.description";
          break;
        }
        case "status": {
          sortKey = "ticket.ticketStatus";
          break;
        }
        default: {
          sortKey = "ticket.createdAt,desc";
        }
      }

      if (sortKey) {
        sort = `${sortKey},${sort}`;
      }

      setTicketQueryData({
        ...ticketQueryData,
        sort: sort,
        isFilter: true,
      });
    } else {
      setTicketQueryData({
        ...ticketQueryData,
        sort: sort,
        isFilter: true,
      });
    }
  }, []);

  return (
    <div className="grow bg-gray-100 text-gray-900">
      <main className="h-full mx-auto px-2 pt-4">
        <div className="flex flex-col h-full pt-6">
          <Table
            columns={columns}
            data={getData() || []}
            manualPagination={true}
            tablePageData={ticketListData?.page}
            queryData={ticketQueryData}
            setQueryData={setTicketQueryData}
            isNoData={isTicketListEmpty && !ticketQueryData?.isSearched}
            noDataText={t(`COMMON.NO_TICKETS_FOUND`)}
            SearchFields={
              <SearchFields
                ticketQueryData={ticketQueryData}
                setTicketQueryData={setTicketQueryData}
              />
            }
            onSortChange={(sortData) => handleSorting(sortData)}
          />
          <Modal
            isOpen={showTicketModal}
            onClose={() => {
              clearTempData();
            }}
            modalTitleText={t(`COMMON.TICKET`)}
            modalContent={
              <>
                <select
                  className={`b-0 border-none focus:outline-none focus:ring-0 ${
                    ticketStatus === "OPEN" ? "text-red-600" : "text-green-600"
                  }`}
                  onChange={(e) => {
                    const value = e.target.value;
                    setTicketStatus(value);
                    value === "CLOSED"
                      ? setBtnDisable(true)
                      : setBtnDisable(false);
                  }}
                  value={ticketStatus}
                >
                  <option value="OPEN">Open</option>
                  <option value="CLOSED">Closed</option>
                </select>
                <div
                  className="max-h-[40vh] overflow-y-auto"
                  id="ticket-scroll-div"
                >
                  {ticketUpdates?.map((ticket, index) => {
                    return (
                      <>
                        {index === 0 && ticketUpdates?.length > 1 && (
                          <div key={ticket?.id} className="flex flex-col my-4">
                            <span className="inter-400 tracking-[2px] text-[#727171] text-[16px]">
                              {moment(ticket?.ticket?.date).format(
                                "MM / DD / YYYY hh:mm A"
                              )}
                            </span>
                            <pre className="inter-400 tracking-[2px] text-[16px] text-[#929292]">
                              {ticket?.ticket?.description}
                            </pre>
                          </div>
                        )}
                        <div key={ticket?.id} className="flex flex-col my-4">
                          <span className="inter-400 tracking-[2px] text-[#727171] text-[16px]">
                            {moment(ticket?.date).format(
                              "MM / DD / YYYY hh:mm A"
                            )}
                          </span>
                          <pre className="inter-400 tracking-[2px] text-[16px] text-[#929292]">
                            {ticket?.note}
                          </pre>
                        </div>
                      </>
                    );
                  })}
                </div>
                {ticketStatus === "OPEN" ? (
                  <div className="my-4">
                    <TextAreaField
                      className="inter-400"
                      value={ticketUpdateTxt}
                      onChange={(e) => {
                        setTicketUpdateTxt(e.target.value);
                      }}
                    />
                  </div>
                ) : null}

                <div className="buttons-wrap text-center">
                  <button
                    className="text-white bg-gradient-to-br from-secondary to-primary rounded-md px-10 py-1 items-center justify-center inter-400 text-[12px]"
                    onClick={(e) => {
                      updateTicket(ticketUpdateTxt);
                    }}
                  >
                    {t(`COMMON.UPDATE`)}
                  </button>
                </div>
              </>
            }
          />
        </div>
      </main>
    </div>
  );
};

export default TicketsPresentational;
