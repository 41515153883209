import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useToastContext } from "@shared/contexts/ToastContext";
import { useLoader } from "@shared/contexts/LoaderContext";
import MyprofilePresentational from "./myprofile-presentational";
import AuthService from "service/auth-service";
import UserService from "service/user-service";

const MyprofileFunctional = () => {
  let navigate = useNavigate();
  const addToast = useToastContext();
  const { setLoader } = useLoader()
  let [isOpen, setIsOpen] = useState(false);

  const [currentUser, setCurrentUser] = useState(AuthService?.getCurrentUser());

  const [myprofileForm, setMyprofileForm] = useState({
    firstName: currentUser?.name?.firstName,
    lastName: currentUser?.name?.lastName,
  });

  const [btnLoading, setBtnLoading] = useState(false);

  const updateUser = (value) => {
    setBtnLoading(true);
    setLoader(true);
    let request = {
      name: {
        firstName: value.firstName || null,
        lastName: value.lastName || null,
      },
      email: currentUser.email,
      role: currentUser.role,
    };

    const userId = currentUser?.userId;
    UserService.updateUser(userId, request)
      .then((res) => {
        if (res.status === 204) {
          addToast({ toast: `COMMON.NO_DATA_FOUND` });
        } else if (res.status === 200) {
          addToast({ toast: `TOASTR.COMMON.PROFILE_UPDATED`, type: "success" });
          setIsOpen(false);
          UserService.currentUser().then((userResponse) => {
            AuthService.setCurrentUser(userResponse.data);
            setCurrentUser(AuthService?.getCurrentUser());
          });
        }
        setBtnLoading(false);
      })
      .catch((e) => {
        addToast({ toast: e });
        setBtnLoading(false);
      })
      .finally(() => setLoader(false))
  };

  function imageUpload(file) {
    setLoader(true);
    let imgFile = file.target.files[0];
    let formData = new FormData();
    let domain = "qolleqt-user";
    let jsonData = '{"attachmentType":"USER_PICTURE"}';
    formData.append("file", imgFile);
    formData.append("jsonData", jsonData);
    UserService.uploadImage(domain, formData)
      .then((res) => {
        let profileImageId = res?.data.attachmentId;
        let userId = currentUser?.userId;
        let request = {
          name: {
            firstName: currentUser?.name.firstName || null,
            lastName: currentUser?.name.lastName || null,
          },
          email: currentUser?.email,
          role: currentUser?.role,
          profileImageId: profileImageId,
        };
        UserService.updateUser(userId, request)
          .then((res) => {
            if (res.status === 204) {
              addToast({ toast: `COMMON.NO_DATA_FOUND` });
            } else if (res.status === 200) {
              addToast({
                toast: `TOASTR.COMMON.PROFILE_UPDATED`,
                type: "success",
              });
              setIsOpen(false);
              UserService.currentUser().then((userResponse) => {
                AuthService.setCurrentUser(userResponse.data);
                setCurrentUser(AuthService?.getCurrentUser());
              });
            }
            setBtnLoading(false);
          })
          .catch((e) => {
            addToast({ toast: e });
          })
          .finally(() => setLoader(false))
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoader(false))
  }

  useEffect(() => {
    setCurrentUser(AuthService?.getCurrentUser());
  }, [currentUser]);

  return (
    <MyprofilePresentational
      currentUserData={currentUser}
      updateUser={updateUser}
      myprofileForm={myprofileForm}
      setMyprofileForm={setMyprofileForm}
      btnLoading={btnLoading}
      imageUpload={imageUpload}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    />
  );
};
export default MyprofileFunctional;
