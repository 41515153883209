import Axios from "axios";
import URLs from "../../config/environment";
import AuthService from "../../service/auth-service";
const TokenInterceptor = () => {
  // Default settings for Axios request
  Axios.defaults.baseURL = URLs.apiUrl;
  Axios.defaults.headers.post["Content-Type"] = "application/json";
  Axios.defaults.validateStatus = () => true;
  Axios.interceptors.request.use(
    (config) => {
      if (AuthService.getToken() && !config?.headers?.skipAuth) {
        config.headers.Authorization = `Bearer ${AuthService.getToken()}`;
        // if (config.method == "get") {
        //   config.params.sort = "createdAt,desc";
        // }
      }
      if (localStorage.getItem("lng")) {
        config.headers["Accept-Language"] =
          `${localStorage.getItem("lng") === "it" ? "IT" : "EN"}` || "EN";
      }
      return config;
    },
    (error) => Promise.reject(error)
  );
};
export default TokenInterceptor;
