import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useToastContext } from "@shared/contexts/ToastContext";
import { useLoader } from "@shared/contexts/LoaderContext";
import RecipeService from "service/recipe-service";
import RecipeManagementPresentational from "./recipe-management-presentational";

const RecipeManagementFunctional = () => {
  const addToast = useToastContext();
  const { setLoader } = useLoader();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    dispatch({ type: "SET_PAGENAME", payload: t(`COMMON.RECIPE_MGMT`) });
  }, [dispatch, t]);

  const defulatRecipeQueryData = {
    userId: "",
    name: "",
    page: 0,
    size: 10,
    sort: "desc",
    isFilter: true,
    isSearched: false,
  };

  const [recipeQueryData, setRecipeQueryData] = useState(
    defulatRecipeQueryData
  );
  const [recipeListData, setRecipeListData] = useState(null);
  const [isRecipeListEmpty, setIsRecipeListEmpty] = useState(false);
  const [tagData, setTagData] = useState(null);

  const fetchRecipeList = useCallback(
    (queryData) => {
      setLoader(true);
      RecipeService.getRecipeList(queryData)
        .then((recipeListResponse) => {
          if (parseInt(recipeListResponse.status, 0) === 204) {
            setIsRecipeListEmpty(true);
            setRecipeListData({});
            // addToast({toast: `COMMON.NO_RECIPES_FOUND`});
          } else {
            // console.log(recipeListResponse?.data)
            setIsRecipeListEmpty(false);
            setRecipeListData(recipeListResponse?.data);
          }
        })
        .catch((e) => {
          addToast({ toast: e });
        })
        .finally(() => setLoader(false));
    },
    []
  );

  const fetchTagData = () => {
    RecipeService.gettaglist()
      .then((tagListResponse) => {
        if (parseInt(tagListResponse.status, 0) === 204) {
          // addToast({toast: `COMMON.NO_RECIPES_FOUND`});
        } else {
          setTagData(tagListResponse?.data?.content);
        }
      })
      .catch((e) => {
        addToast({ toast: e });
      });
  };

  const updateRecipeStatus = (id, status) => {
    // console.log(id, status)
    setLoader(true);
    RecipeService.updateRecipeStatus(id, status)
      .then((recipeStateUpdateResponse) => {
        if (parseInt(recipeStateUpdateResponse.status, 0) === 204) {
          addToast({ toast: `COMMON.NO_DATA_FOUND` });
        } else {
          fetchRecipeList(recipeQueryData);
          addToast({
            toast: `TOASTR.COMMON.RECIPE_STATE_CHANGED`,
            type: "success",
          });
        }
      })
      .catch((e) => {
        addToast({ toast: e });
      })
      .finally(() => setLoader(false));
  };

  const deleteRecipe = (id) => {
    setLoader(true);
    RecipeService.deleteRecipe(id)
      .then((recipeDeleteResponse) => {
        if (parseInt(recipeDeleteResponse.status, 0) === 204) {
          addToast({ toast: `COMMON.NO_DATA_FOUND` });
        } else {
          const maxPageWillAvail =
            Math.floor(
              (recipeListData?.page?.totalElements - 1) /
              recipeListData?.page?.size
            ) +
            ((recipeListData?.page?.totalElements - 1) %
              recipeListData?.page?.size >
              0
              ? 1
              : 0);
          if (Number(recipeListData?.page?.number) >= maxPageWillAvail) {
            setRecipeQueryData({
              ...recipeQueryData,
              page: maxPageWillAvail - 1,
            });
          } else {
            fetchRecipeList(recipeQueryData);
          }
          addToast({
            toast: `TOASTR.COMMON.RECIPE_DELETE_SUCCESS`,
            type: "success",
          });
        }
      })
      .catch((e) => {
        addToast({ toast: e });
      })
      .finally(() => setLoader(false));
  };

  useEffect(() => {
    if (recipeQueryData?.isFilter) {
      setRecipeQueryData({ ...recipeQueryData, isFilter: false })
      fetchRecipeList(recipeQueryData);
    }
  }, [recipeQueryData]);

  useEffect(() => {
    fetchTagData();
  }, []);

  return (
    <RecipeManagementPresentational
      isRecipeListEmpty={isRecipeListEmpty}
      recipeListData={recipeListData}
      recipeQueryData={recipeQueryData}
      setRecipeQueryData={setRecipeQueryData}
      updateRecipeStatus={updateRecipeStatus}
      deleteRecipe={deleteRecipe}
      tagList={tagData}
    />
  );
};
export default RecipeManagementFunctional;
