import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, Transition } from "@headlessui/react";
// import { TrashIcon } from "@heroicons/react/24/solid";

export default function Toggle(props) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const {
    className = "",
    warningTitle = "Are you sure want to change the status ?",
    warningText = "",
    conformText = t(`COMMON.CONFIRM`),
    cancelText = t(`COMMON.CANCEL`),
    iconColorClass = "text-red-600",
    lebel = "",
    checked = false,
    onChange = () => {},
    onClickConfirm = () => {},
    ...rest
  } = props;
  const uniqueId = Math.random().toString(36).substr(2, 9);
  return (
    <>
      <div
        className={`flex${className ? " " + className : ""}`}
        onClick={() => setIsOpen(true)}
      >
        <label
          htmlFor={uniqueId}
          className="flex relative items-center cursor-pointer"
        >
          <input
            {...rest}
            type="checkbox"
            id={uniqueId}
            className="sr-only peer"
            checked={checked}
            onChange={(e) => onChange(e)}
          />
          <div className="w-9 h-5 bg-red-700 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2.8px] after:left-[2px] after:bg-white after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-green-500"></div>
          {lebel && <span className="ml-3 text-sm font-medium">{lebel}</span>}
        </label>
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setIsOpen(true)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-10 text-center align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h2"
                    className="text-2xl font-medium leading-6 text-gray-500"
                  >
                    {warningTitle}
                  </Dialog.Title>
                  {warningText && (
                    <div className="mt-4">
                      <p className="text-lg text-gray-500">{warningText}</p>
                    </div>
                  )}
                  <div className="mt-8">
                    <button
                      type="button"
                      className="inline-flex justify-center mx-5 rounded-md border border-transparent bg-red-500 px-16 py-3 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                      onClick={(e) => {
                        onClickConfirm();
                        setIsOpen(false);
                      }}
                    >
                      {conformText}
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center mx-5 rounded-md border border-transparent bg-gray-400 px-16 py-3 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                      onClick={() => setIsOpen(false)}
                    >
                      {cancelText}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* <div className={`flex${className ? " " + className : ""}`}>
        <label
          htmlFor={uniqueId}
          className="flex relative items-center cursor-pointer"
        >
          <input
            {...rest}
            type="checkbox"
            id={uniqueId}
            className="sr-only peer"
            checked={checked}
            onChange={(e) => onChange(e)}
          />
          <div className="w-9 h-5 bg-red-700 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-green-500"></div>
          {lebel && <span className="ml-3 text-sm font-medium">{lebel}</span>}
        </label>
      </div> */}
    </>
  );
}
