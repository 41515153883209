import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useToastContext } from "@shared/contexts/ToastContext";
import { useLoader } from "@shared/contexts/LoaderContext";
import TicketService from "service/ticket-service";
import TicketsPresentational from "./tickets-presentational";

const TicketstFunctional = () => {
  const addToast = useToastContext();
  const { setLoader } = useLoader();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    dispatch({ type: "SET_PAGENAME", payload: t(`COMMON.TICKETS_MGMT`) });
  }, [dispatch, t]);

  const defulatTicketQueryData = {
    // search: "",
    page: 0,
    size: 10,
    sort: "ticket.createdAt.desc",
  };

  const [ticketQueryData, setTicketQueryData] = useState(
    defulatTicketQueryData
  );
  const [ticketListData, setTicketListData] = useState({});
  const [isTicketListEmpty, setIsTicketListEmpty] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(0);
  const [ticketDate, setTicketDate] = useState(null);
  const [ticketDescription, setTicketDescription] = useState(null);
  const [ticketStatus, setTicketStatus] = useState(null);
  const [ticketId, setTicketId] = useState(null);
  const [btnDisable, setBtnDisable] = useState(false);
  const [showTicketModal, setShowTicketModal] = useState(false);
  const [ticketData, setTicketData] = useState(null);
  const [ticketUpdates, setTicketUpdates] = useState([]);
  const [ticketUpdateTxt, setTicketUpdateTxt] = useState("");

  const fetchTicketList = useCallback((params) => {
    setLoader(true);
    TicketService.getTicketList(params)
      .then((res) => {
        if (parseInt(res.status, 0) === 204) {
          setIsTicketListEmpty(true);
          setTicketListData({});
          // addToast({toast: `COMMON.NO_USERS_FOUND`});
        } else {
          // console.log(res?.data)
          setIsTicketListEmpty(false);
          setTicketListData(res?.data);
        }
      })
      .catch((e) => {
        addToast({ toast: e });
      })
      .finally(() => setLoader(false));
  }, []);

  const updateTicketStatus = (id, status) => {
    setLoader(true);
    TicketService.updateTicketStatus(id, status)
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          if (res?.status === 204) {
            addToast({ toast: `COMMON.NO_TICKETS_FOUND` });
          }
          fetchTicketList(ticketQueryData);
          setTicketStatus(status);
          addToast({
            toast: `TOASTR.COMMON.TICKET_STATE_CHANGED`,
            type: "success",
          });
        } else {
          const errors = res?.data?.errors;
          if (errors?.length) {
            if (errors[0]?.message === "TICKET_ALREADY_CLOSED") {
              addToast({
                toast: "Ticket already closed",
                type: "error",
              });
            }
          }
        }
      })
      .catch((e) => {
        addToast({ toast: e });
      })
      .finally(() => setLoader(false));
  };

  const updateTicketNotes = (id, notes, ticketStatus) => {
    setLoader(true);
    TicketService.updateTicketNotes(id, notes, ticketStatus)
      .then((res) => {
        if (res?.status === 204) {
          addToast({ toast: `COMMON.NO_TICKETS_FOUND` });
        } else {
          fetchTicketList(ticketQueryData);
          addToast({
            toast: `TOASTR.COMMON.TICKET_NOTE_SAVED`,
            type: "success",
          });
        }
      })
      .catch((e) => {
        addToast({ toast: e });
      })
      .finally(() => {
        setLoader(false);
        clearTempData();
      });
  };

  const clearTempData = () => {
    setTicketUpdateTxt("");
    setShowTicketModal(false);
    setTicketData(null);
    setTicketUpdates([]);
  };

  const openTicket = (data) => {
    setTicketStatus(data?.ticketStatus);
    setTicketData(data);
    setLoader(true);
    TicketService.getTicketHistrory(data?.id)
      .then((res) => {
        setShowTicketModal(true);
        setTicketUpdates(res?.data?.content || []);
        setTimeout(() => {
          let objDiv = document?.getElementById("ticket-scroll-div");
          objDiv.scrollTop = objDiv?.scrollHeight;
        }, 100);
      })
      .catch((e) => {
        addToast({ toast: "Something went wrong", status: "error" });
      })
      .finally(() => setLoader(false));
  };

  const updateTicket = (value = "") => {
    if (value === "" && ticketStatus === "OPEN") {
      addToast({ toast: "Please provide the note", status: "error" });
      return;
    }
    updateTicketNotes(ticketData?.id, value, ticketStatus);
  };

  useEffect(() => {
    if (ticketQueryData?.isFilter) {
      setTicketListData({ ...ticketQueryData, isFilter: false });
      fetchTicketList(ticketQueryData);
    }
  }, [ticketQueryData]);

  return (
    <TicketsPresentational
      isTicketListEmpty={isTicketListEmpty}
      ticketQueryData={ticketQueryData}
      setTicketQueryData={setTicketQueryData}
      ticketListData={ticketListData}
      updateTicketStatus={updateTicketStatus}
      selectedTicket={selectedTicket}
      setSelectedTicket={setSelectedTicket}
      ticketDate={ticketDate}
      setTicketDate={setTicketDate}
      ticketDescription={ticketDescription}
      setTicketDescription={setTicketDescription}
      ticketStatus={ticketStatus}
      setTicketStatus={setTicketStatus}
      ticketId={ticketId}
      setTicketId={setTicketId}
      updateTicketNotes={updateTicketNotes}
      btnDisable={btnDisable}
      setBtnDisable={setBtnDisable}
      showTicketModal={showTicketModal}
      setShowTicketModal={setShowTicketModal}
      openTicket={openTicket}
      updateTicket={updateTicket}
      ticketData={ticketData}
      ticketUpdates={ticketUpdates}
      setTicketUpdates={setTicketUpdates}
      ticketUpdateTxt={ticketUpdateTxt}
      setTicketUpdateTxt={setTicketUpdateTxt}
      clearTempData={clearTempData}
    />
  );
};
export default TicketstFunctional;
