export function SortIcon({ className, upClassName = `fill-[#BCBCBC]`, downClassName = `fill-[#BCBCBC]` }) {
  return (
    <svg className={className} width="18" height="30" viewBox="0 0 18 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className={upClassName} d="M9.40328 29.566C9.21367 29.8439 8.95653 29.9999 8.68841 29.9999C8.4203 29.9999 8.16316 29.8439 7.97354 29.566L0.303121 18.3238C0.161755 18.1166 0.0654882 17.8525 0.0264914 17.5651C-0.0125054 17.2777 0.0075183 16.9798 0.0840305 16.709C0.160543 16.4382 0.290108 16.2068 0.456348 16.044C0.622587 15.8811 0.818037 15.7942 1.01799 15.7941L16.3588 15.7941C16.5588 15.7942 16.7542 15.8811 16.9205 16.044C17.0867 16.2068 17.2163 16.4382 17.2928 16.709C17.3693 16.9798 17.3893 17.2777 17.3503 17.5651C17.3113 17.8525 17.2151 18.1166 17.0737 18.3238L9.40328 29.566Z" />
      <path className={downClassName} d="M7.96691 0.44351C8.15624 0.165491 8.41322 0.00915603 8.68133 0.00888374C8.94945 0.00861145 9.20675 0.164424 9.39664 0.442058L17.0785 11.6765C17.2201 11.8836 17.3166 12.1475 17.3559 12.4349C17.3952 12.7223 17.3754 13.0202 17.2992 13.2911C17.223 13.5619 17.0936 13.7935 16.9276 13.9565C16.7615 14.1195 16.5661 14.2066 16.3662 14.2069L1.02534 14.2225C0.825389 14.2226 0.629851 14.1359 0.463446 13.9732C0.297041 13.8105 0.167241 13.5792 0.0904537 13.3086C0.0136666 13.0379 -0.00665926 12.74 0.0320456 12.4525C0.0707504 12.165 0.166748 11.9009 0.307903 11.6935L7.96691 0.44351Z" />
    </svg>
  )
}

export function SortUpIcon({ className }) {
  return (
    <svg className={className} stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 320 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z"></path></svg>
  )
}

export function SortDownIcon({ className }) {
  return (
    <svg className={className} stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 320 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z"></path></svg>
  )
}