import React, { createContext, useState, useEffect, useContext } from "react";

const Context = createContext();

export const LoaderContextProvider = ({ children }) => {
  const [loader, setLoader] = useState(false);
  const exposed = {
    loader,
    setLoader,
  };

  function disableScroll() {
    if (typeof window !== 'undefined') {
      // Get the current page scroll position
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
      // if any scroll is attempted,
      // set this to the previous value
      window.onscroll = function () {
        window.scrollTo(scrollLeft, scrollTop);
      };
    }
  }

  function enableScroll() {
    window.onscroll = function () { };
  }

  useEffect(() => {
    if (loader) {
      disableScroll()
    } else {
      enableScroll()
    }
  }, [loader])

  return (
    <Context.Provider value={exposed}>
      {children}
      {loader && (
        <div className="bg-white/60 fixed inset-0 flex items-center justify-center z-[999] cursor-progress backdrop-blur-sm">
          <div className='flex items-center text-2xl text-type-dark font-semibold'>
            {/* <img
              src={Images?.loader}
              alt={"Page Loader"}
              className="inline-block w-[200px] h-[200px]"
              width={200}
              height={200}
              priority
            /> */}
            Loading...
          </div>
        </div>
      )}
    </Context.Provider>
  );
};

export const useLoader = () => useContext(Context);