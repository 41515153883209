import React, { useEffect, useState } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import { useToastContext } from "../../../@shared/contexts/ToastContext";
import ResetPasswordPresentational from "./reset-password-presentational";
import LoginService from "../../../service/login-service";
import AuthService from "../../../service/auth-service";

const ResetPasswordFunctional = () => {

  const addToast = useToastContext();
  const navigate = useNavigate();
  const [passwordResetForm, setPasswordResetForm] = useState({
    oldPassword: '',
    newPassword: ''
  });
  const [btnLoading, setBtnLoading] = useState(false);
  const passwordResetClicked = () => {
    setBtnLoading(true);
    let request = {
      newPassword: btoa(passwordResetForm.newPassword) || null,
      oldPassword: btoa(passwordResetForm.oldPassword) || null
    };
    LoginService.resetPassword(request).then((res) => {
      if (res.status === 204) {
        addToast({toast: `TOASTR.COMMON.OLD_PASSWORD_INVALID`});
      } else if (res.status === 200) {
        addToast({toast: `TOASTR.COMMON.PASSWORD_RESET_SUCCESS`, type:`success`});
        localStorage.clear();
        AuthService.logout();
        backToLogin();
      }
      setBtnLoading(false);
    })
    .catch((e) => {
      addToast({toast: e});
      setBtnLoading(false);
    });
  };

  const backToLogin = () => {
    navigate("/login");
  };


  return (
    <ResetPasswordPresentational
      passwordResetForm={passwordResetForm}
      passwordResetClicked={passwordResetClicked}
      setPasswordResetForm={setPasswordResetForm}
      btnLoading={btnLoading}
    />
  );
};
export default ResetPasswordFunctional;
