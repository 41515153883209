import { Bar } from "react-chartjs-2";

function BarChart({ udata, customBarChart = false, customBarChartData = {} }) {
  let title = udata?.title;
  let icon = udata?.icon;
  let labels = udata?.labels ?? [];
  let count = udata?.count ?? [];

  const parsedData = {
    labels: labels,
    datasets: [
      {
        data: count,
        backgroundColor: ["#C21F4E", "#F09A53"],
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    borderSkipped: true,
    parsing: {
      xAxisKey: "country",
      yAxisKey: "activeUsersCount",
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawTicks: false,
        },
        ticks: {
          padding: 8,
        },
      },
      y: {
        beginAtZero: true,
        display: false,
        grid: {
          display: false,
        },
      },
    },
  };

  return (
    <div className="bg-white drop-shadow-dashboard-chart rounded-md shadow p-5">
      <div className="text-sm text-gray-600 mb-3 font-Biko inline-flex justify-center">
        <img src={icon} className="mr-1" />
        {title}
      </div>
      <div className="">
        <Bar
          datasetIdKey={Math.floor(Math.random() * 100)}
          data={customBarChart ? customBarChartData : parsedData}
          options={options}
        />
      </div>
    </div>
  );
}

export default BarChart;
