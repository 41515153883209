import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useToastContext } from "@shared/contexts/ToastContext";
import { useLoader } from "@shared/contexts/LoaderContext";
import UserService from "service/user-service";
import RecipeService from "service/recipe-service";
import UserProfilePresentationl from "./user-profile-presentational";

const UserProfileFunctional = () => {
  const { id } = useParams();
  let navigate = useNavigate();
  const addToast = useToastContext();
  const { setLoader } = useLoader();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch({ type: "SET_PAGENAME", payload: t(`COMMON.USER_DETAIL`) });
  }, [dispatch, t]);

  const defulatRecipeQueryData = {
    userId: id,
    name: "",
    page: 0,
    size: 10,
    sort: "desc",
    isFilter: true,
    isSearched: false,
  };

  const totalIntialCall = 3;
  let completedCalls = 0;

  const [userData, setUserData] = useState(null);
  const [userAnalyticsData, setUserAnalyticsData] = useState(null);
  const [recipeQueryData, setRecipeQueryData] = useState(
    defulatRecipeQueryData
  );
  const [recipeListData, setRecipeListData] = useState(null);
  const [isRecipeListEmpty, setIsRecipeListEmpty] = useState(false);

  const fetchUser = useCallback(
    (id) => {
      setLoader(true);
      UserService.getUser(id)
        .then((userResponse) => {
          if (parseInt(userResponse.status, 0) === 204) {
            // addToast({toast: `COMMON.NO_DATA_FOUND`});
          } else {
            setUserData(userResponse?.data);
          }
        })
        .catch((e) => {
          addToast({ toast: e });
        })
        .finally(() => {
          if (completedCalls + 1 < totalIntialCall) {
            completedCalls++;
          } else {
            setLoader(false);
          }
        });
    },
    [addToast]
  );

  const fetchUserAnalytics = useCallback(
    (id) => {
      setLoader(true);
      RecipeService.getRecipeAnalytics(id)
        // UserService.getUserAnalytics(id)
        .then((userAnalyticsResponse) => {
          if (parseInt(userAnalyticsResponse.status, 0) === 204) {
            // addToast({toast: `COMMON.NO_DATA_FOUND`});
          } else {
            setUserAnalyticsData(userAnalyticsResponse?.data);
          }
        })
        .catch((e) => {
          addToast({ toast: e });
        })
        .finally(() => {
          if (completedCalls + 1 < totalIntialCall) {
            completedCalls++;
          } else {
            setLoader(false);
          }
        });
    },
    [addToast]
  );

  const updateUserStatus = (id, status) => {
    setLoader(true);
    UserService.updateUserStatus(id, status)
      .then((userStateUpdateResponse) => {
        if (parseInt(userStateUpdateResponse.status, 0) === 204) {
          addToast({ toast: `COMMON.NO_DATA_FOUND` });
        } else {
          addToast({
            toast: `TOASTR.COMMON.USER_STATE_CHANGED`,
            type: "success",
          });
          fetchUser(id);
        }
      })
      .catch((e) => {
        addToast({ toast: e });
      })
      .finally(() => setLoader(false));
  };

  const deleteUser = (id) => {
    setLoader(true);
    UserService.deleteUser(id)
      .then((userDeleteResponse) => {
        if (parseInt(userDeleteResponse.status, 0) === 204) {
          addToast({ toast: `COMMON.NO_DATA_FOUND` });
        } else {
          navigate("/user-management");
          addToast({
            toast: `TOASTR.COMMON.USER_DELETE_SUCCESS`,
            type: "success",
          });
        }
      })
      .catch((e) => {
        addToast({ toast: e });
      })
      .finally(() => setLoader(false));
  };

  const fetchRecipeList = useCallback(
    (recipeQueryData) => {
      setLoader(true);
      RecipeService.getRecipeList(recipeQueryData)
        .then((recipeListResponse) => {
          if (parseInt(recipeListResponse.status, 0) === 204) {
            setIsRecipeListEmpty(true);
            setRecipeListData({});
            // addToast({toast: `COMMON.NO_RECIPES_FOUND`});
          } else {
            setIsRecipeListEmpty(false);
            setRecipeListData(recipeListResponse?.data);
          }
        })
        .catch((e) => {
          addToast({ toast: e });
        })
        .finally(() => {
          if (completedCalls + 1 < totalIntialCall) {
            completedCalls++;
          } else {
            setLoader(false);
          }
        });
    },
    [addToast]
  );

  const updateRecipeStatus = (id, status) => {
    setLoader(true);
    RecipeService.updateRecipeStatus(id, status)
      .then((recipeUpdateResponse) => {
        if (parseInt(recipeUpdateResponse.status, 0) === 204) {
          addToast({ toast: `COMMON.NO_DATA_FOUND` });
        } else {
          fetchRecipeList(recipeQueryData);
          fetchUser(id);
          addToast({
            toast: `TOASTR.COMMON.RECIPE_STATE_CHANGED`,
            type: "success",
          });
        }
      })
      .catch((e) => {
        addToast({ toast: e });
      })
      .finally(() => setLoader(false));
  };

  const deleteRecipe = (id) => {
    setLoader(true);
    RecipeService.deleteRecipe(id)
      .then((recipeDeleteResponse) => {
        if (parseInt(recipeDeleteResponse.status, 0) === 204) {
          addToast({ toast: `COMMON.NO_DATA_FOUND` });
        } else {
          const maxPageWillAvail =
            Math.floor(
              (recipeListData?.page?.totalElements - 1) /
                recipeListData?.page?.size
            ) +
            ((recipeListData?.page?.totalElements - 1) %
              recipeListData?.page?.size >
            0
              ? 1
              : 0);
          if (Number(recipeListData?.page?.number) >= maxPageWillAvail) {
            setRecipeQueryData({
              ...recipeQueryData,
              page: maxPageWillAvail - 1,
            });
          } else {
            fetchRecipeList(recipeQueryData);
            fetchUser(id);
          }
          addToast({
            toast: `TOASTR.COMMON.RECIPE_DELETE_SUCCESS`,
            type: "success",
          });
        }
      })
      .catch((e) => {
        addToast({ toast: e });
      })
      .finally(() => setLoader(false));
  };

  useEffect(() => {
    fetchUser(id);
  }, [fetchUser, id]);

  useEffect(() => {
    fetchUserAnalytics(id);
  }, [fetchUserAnalytics, id]);

  useEffect(() => {
    if (recipeQueryData?.isFilter) {
      setRecipeQueryData({ ...recipeQueryData, isFilter: false });
      fetchRecipeList(recipeQueryData);
    }
  }, [fetchRecipeList, recipeQueryData]);

  return (
    <UserProfilePresentationl
      userData={userData}
      deleteUser={deleteUser}
      updateUserStatus={updateUserStatus}
      userAnalyticsData={userAnalyticsData}
      isRecipeListEmpty={isRecipeListEmpty}
      recipeListData={recipeListData}
      recipeQueryData={recipeQueryData}
      setRecipeQueryData={setRecipeQueryData}
      updateRecipeStatus={updateRecipeStatus}
      deleteRecipe={deleteRecipe}
    />
  );
};
export default UserProfileFunctional;
