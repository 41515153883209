import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select from "react-tailwindcss-select";
import Table, {
  AvatarCell,
  // SelectColumnFilter,
  // StatusPill
} from "@shared/component/table/table";
import Toggle from "@shared/component/toggle";
import DeletePopup from "@shared/component/delete-popup";
import UserAvatar from "assets/image/profile.png";

const SearchFields = ({ recipeQueryData, setRecipeQueryData, tagList }) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [creator, setCreator] = useState("");
  const [rating, setRating] = useState("");
  const [substitutionCount, setSubstitutionCount] = useState("");
  const [commentCount, setCommentCount] = useState("");
  const [tags, setTags] = useState(null);

  return (
    <>
      <div className="flex gap-6 mb-5">
        <div className="flex flex-wrap items-center flex-1 gap-6">
          <div className="flex-item flex-1">
            <input
              type="text"
              className="py-2.5 text-sm text-gray-500 bg-white border-primary rounded-md w-full max-w-full focus:outline-none focus:ring-primary focus:border-primary"
              placeholder="Recipe Name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              autoComplete="off"
            />
          </div>
          <div className="flex-item flex-1">
            <input
              type="text"
              className="py-2.5 text-sm text-gray-500 bg-white border-primary rounded-md w-full max-w-full focus:outline-none focus:ring-primary focus:border-primary"
              placeholder="Creator Name"
              name="creator"
              value={creator}
              onChange={(e) => setCreator(e.target.value)}
              autoComplete="off"
            />
          </div>
          <div className="flex-item flex-1">
            <input
              type="number"
              min="0"
              className="py-2.5 text-sm text-gray-500 bg-white border-primary rounded-md w-full max-w-full focus:outline-none focus:ring-primary focus:border-primary"
              placeholder="Rating"
              name="rating"
              value={rating}
              onChange={(e) => setRating(e.target.value)}
              autoComplete="off"
            />
          </div>
          <div className="flex-item flex-1">
            <input
              type="number"
              min="0"
              className="py-2.5 text-sm text-gray-500 bg-white border-primary rounded-md w-full max-w-full focus:outline-none focus:ring-primary focus:border-primary"
              placeholder="Substitution Count"
              name="substitutionCount"
              value={substitutionCount}
              onChange={(e) => setSubstitutionCount(e.target.value)}
              autoComplete="off"
            />
          </div>
          <div className="flex-item flex-1">
            <input
              type="number"
              min="0"
              className="py-2.5 text-sm text-gray-500 bg-white border-primary rounded-md w-full max-w-full focus:outline-none rin focus:ring-primary focus:border-primary"
              placeholder="Comment Count"
              name="commentCount"
              value={commentCount}
              onChange={(e) => setCommentCount(e.target.value)}
              autoComplete="off"
            />
          </div>
          <div className="flex-item flex-auto w-full">
            <Select
              classNames={{
                menuButton: ({ isDisabled }) =>
                  `flex text-sm text-gray-500 bg-white border border-primary rounded-md focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary`,
                list: "px-2.5 max-h-[50vh] overflow-auto",
                listItem: ({ isSelected }) =>
                  `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded text-gray-500 hover:bg-primaryLite hover:text-primary`,
                tagItem: ({ item, isDisabled }) =>
                  `bg-primaryLite text-primary border rounded-sm flex space-x-1 pl-1`,
                tagItemText: `py-px`,
              }}
              placeholder={
                <span className="leading-[1.715]">
                  {t(`COMMON.SELECT_TAGS`)}
                </span>
              }
              options={tagList?.map((val) => ({
                label: val.groupName,
                options: val?.tags?.map(({ tag, id }) => ({
                  label: tag,
                  value: id,
                })),
              }))}
              value={tags}
              onChange={(newTags) => setTags(newTags)}
              className="py-2.5 text-sm text-gray-500 bg-white border-primary rounded-md w-full max-w-full focus:outline-none focus:ring-primary focus:border-primary"
              isMultiple
              isClearable
              isSearchable
            />
          </div>
        </div>
        <div className="flex flex-col justify-between">
          <button
            className={`inline-flex justify-center float-right rounded-md border-transparent bg-gradient-primary px-10 py-2 text-lg text-white focus:outline-none disabled:opacity-25 disabled:cursor-not-allowed`}
            onClick={() => {
              setRecipeQueryData({
                ...recipeQueryData,
                name: name || null,
                userName: creator || null,
                rating: rating || null,
                commentCount: commentCount || null,
                substitutionCount: substitutionCount || null,
                tagIds:
                  tags?.length > 0
                    ? tags?.map(({ value }) => value)?.join()
                    : null,
                isFilter: true,
                isSearched: true,
                page: 0,
              });
            }}
          >
            {t(`COMMON.SEARCH`)}
          </button>
          <button
            className={`inline-flex justify-center float-right rounded-md border-transparent bg-gradient-primary px-10 py-2 text-lg text-white focus:outline-none disabled:opacity-25 disabled:cursor-not-allowed`}
            onClick={() => {
              setRecipeQueryData({
                ...recipeQueryData,
                name: null,
                userName: null,
                rating: null,
                commentCount: null,
                substitutionCount: null,
                tagIds: [],
                isFilter: true,
                isSearched: false,
                page: 0,
              });
              setName("");
              setCreator("");
              setRating("");
              setSubstitutionCount("");
              setCommentCount("");
              setTags(null);
            }}
          >
            {t(`COMMON.CLEAR`)}
          </button>
        </div>
      </div>
    </>
  );
};

const RecipeManagement = ({
  isRecipeListEmpty,
  recipeListData,
  recipeQueryData,
  setRecipeQueryData,
  updateRecipeStatus,
  deleteRecipe,
  tagList,
}) => {
  const { t } = useTranslation();
  const columns = React.useMemo(
    () => [
      {
        Header: t(`COMMON.SNO`),
        accessor: "sno",
        disableSortBy: true,
      },
      {
        Header: t(`COMMON.RECIPES`),
        accessor: "recipe_name",
      },
      {
        Header: t(`COMMON.CREATOR`),
        accessor: "creator",
        Cell: AvatarCell,
        imgAccessor: "imgUrl",
        AvatarCellLink: "link",
      },
      {
        Header: t(`COMMON.RATING`),
        accessor: "rating",
      },
      {
        Header: t(`COMMON.COMMENTS`),
        accessor: "comments",
      },
      {
        Header: t(`COMMON.SUBSTITUTIONCOUNT`),
        accessor: "substitutionCount",
      },
      {
        Header: t(`COMMON.ACTION`),
        accessor: "action",
        disableSortBy: true,
      },
    ],
    [t]
  );

  const getData = useCallback(() => {
    const snoBase = recipeListData?.page?.number * recipeListData?.page?.size;
    const recipeList = recipeListData?.content?.map(function (recipe, index) {
      const sno = snoBase + index + 1;
      const recipeState =
        recipe?.state?.toLowerCase() === "active" ? true : false;
      return {
        sno: sno,
        recipe_name: (
          <Link to={`/recipe-info/${recipe?.id}`} className="text-primary">
            {recipe?.name}
          </Link>
        ),
        creator: (
          <span className="text-primary">
            {recipe?.user?.name &&
              Object?.values(recipe?.user?.name)?.join(" ")?.trim()}
          </span>
        ),
        comments: recipe?.commentCount || 0,
        rating: recipe?.rating || 0,
        substitutionCount: recipe?.substitutionCount || 0,
        imgUrl: recipe?.user?.profileImage?.url || UserAvatar,
        link: "/user-profile/" + recipe?.user?.id,
        action: (
          <>
            <div className="flex">
              <Toggle
                checked={recipeState}
                onClickConfirm={(e) => {
                  updateRecipeStatus(
                    recipe?.id,
                    recipeState === true ? "INACTIVE" : "ACTIVE"
                  );
                }}
              />
              <DeletePopup
                warningTitle="Delete Recipe?"
                className="ml-2"
                onClickConfirm={() => {
                  deleteRecipe(recipe?.id);
                }}
              />
            </div>
          </>
        ),
      };
    });
    return recipeList;
  }, [recipeListData]);

  const handleSorting = React.useCallback((sortData) => {
    let sort = "desc";
    if (sortData?.length > 0) {
      const sortKeyId = sortData?.[0]?.id;
      sort = sortData?.[0]?.desc ? "desc" : "asc";
      let sortKey = "";
      switch (sortKeyId) {
        case "recipe_name": {
          sortKey = "recipe.name";
          break;
        }
        case "creator": {
          sortKey = "recipe.userName";
          break;
        }
        case "rating": {
          sortKey = "recipe.rating";
          break;
        }
        case "substitutionCount": {
          sortKey = "recipe.substitutionCount";
          break;
        }
        case "comments": {
          sortKey = "recipe.commentCount";
          break;
        }
        default: {
          sortKey = "";
        }
      }

      if (sortKey) {
        sort = `${sortKey},${sort}`;
      }

      setRecipeQueryData({
        ...recipeQueryData,
        sort: sort,
        isFilter: true,
      });
    } else {
      setRecipeQueryData({
        ...recipeQueryData,
        sort: sort,
        isFilter: true,
      });
    }
  }, []);

  return (
    <div className="grow bg-gray-100 text-gray-900">
      <main className="h-full mx-auto">
        <div className="flex flex-col h-full">
          <Table
            columns={columns}
            data={getData() || []}
            manualPagination={true}
            tablePageData={recipeListData?.page}
            queryData={recipeQueryData}
            setQueryData={setRecipeQueryData}
            isNoData={isRecipeListEmpty && !recipeQueryData?.isSearched}
            noDataText={t(`COMMON.NO_RECIPES_FOUND`)}
            SearchFields={
              <SearchFields
                recipeQueryData={recipeQueryData}
                setRecipeQueryData={setRecipeQueryData}
                tagList={tagList}
              />
            }
            onSortChange={(sortData) => handleSorting(sortData)}
          />
        </div>
      </main>
    </div>
  );
};

export default RecipeManagement;
