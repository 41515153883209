import axios from "axios";
import { UserUrl } from "./url";
import { RecipeUrl } from "./url";

const getTicketList = (params = {}) => {
  return axios.get(RecipeUrl.TICKET, { params });
};

const getTicket = (id, params) => {
  return axios.get(UserUrl.TICKET_RECEIPE + `/${id}`, { params });
};
const getTicketHistrory = (id, params) => {
  return axios.get(UserUrl.TICKET_RECEIPE + `/${id}/history`, { params });
};

const createTicket = (id, body) => {
  return axios.post(UserUrl.TICKET + `/${id}`);
};

const updateTicket = (id, body) => {
  return axios.put(UserUrl.TICKET + `/${id}`, body);
};

const updateTicketStatus = (id, status) => {
  let body = {
    ticketStatus: status,
  };
  return axios.put(RecipeUrl.TICKET + `/${id}`, body);
};

const updateTicketNotes = (id, note, ticketStatus) => {
  let body = {
    note: note,
    ticketStatus: ticketStatus,
  };
  return axios.put(RecipeUrl.TICKET + `/${id}`, body);
};

const TicketService = {
  getTicketList,
  getTicket,
  createTicket,
  getTicketHistrory,
  updateTicket,
  updateTicketStatus,
  updateTicketNotes,
};

export default TicketService;
