let initialState = {
  pageName: "Dashboard",
  notificationCount: 0,
}

function HeaderReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_NOTIFICATION_COUNT":
      return {
        ...state, notificationCount : action.payload
      };
    case "SET_PAGENAME":
      return {
        ...state, pageName : action.payload
      };
    default:
      return state;
  }
}
export default HeaderReducer;
