import React from "react";
import { Routes as BrowserRoutes, Route, Navigate } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import Login from "../@modules/@admin/login/login-functional";
import ForgetPassword from "../@modules/@admin/forget-password/forgot-password-functional";
import Dashboard from "../@modules/@admin/dashboard/dashboard-functional";
import UserManagement from "../@modules/@admin/user-management/user-management-functional";
import UserProfile from "../@modules/@admin/user-profile/user-profile-functional";
import RecipeManagement from "../@modules/@admin/recipe-management/recipe-management-functional";
import RecipeInfo from "../@modules/@admin/recipe-info/recipe-info-functional";
import Tickets from "../@modules/@admin/tickets/tickets-functional";
import MyProfile from "../@modules/@admin/myprofile/myprofile-functional";
import AccVerification from "@modules/@common/accountverification/verification";

const Routes = () => {
  return (
    <BrowserRoutes>
      <Route path="/login" element={<Login />} />
      <Route path="/forget-password" element={<ForgetPassword />} />
      <Route path="/email-verification" element={<AccVerification />} />
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="" element={<PrivateRoute />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/user-management" element={<UserManagement />} />
        <Route path="/user-profile/:id" element={<UserProfile />} />
        <Route path="/recipe-management" element={<RecipeManagement />} />
        <Route path="/recipe-info/:id" element={<RecipeInfo />} />
        <Route path="/tickets" element={<Tickets />} />
        <Route path="/my-profile" element={<MyProfile />} />
      </Route>
      <Route path="*" element={<Navigate to="/" />} />
    </BrowserRoutes>
  );
};

export default Routes;
