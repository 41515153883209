import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useToastContext } from "../../../@shared/contexts/ToastContext";
import LoginService from "../../../service/login-service";
import ForgetPasswordPresentational from "./forgot-password-presentational";

const ForgetPasswordFunctional = () => {
  const [ searchParams ] = useSearchParams();
  useEffect(() => {
    if (searchParams?.get('email')) {
      setEmail(searchParams?.get('email'));
    }
  }, [searchParams]);

  const addToast = useToastContext();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);

  const sendEmailClicked = () => {
    setBtnLoading(true);
    let request = {
      email: email
    };
    LoginService.forgetPassword(request).then((res) => {
      if (res.status === 204) {
        addToast({toast: `TOASTR.API.INVALID_EMAIL`});
      } else if (res.status === 200) {
        addToast({toast: `TOASTR.COMMON.EMAIL_SEND_SUCCESSFULLY`});
        setEmail("");
        backToLogin();
      }
      setBtnLoading(false);
    })
    .catch((e) => {
      addToast({toast: e});
      setBtnLoading(false);
    });
  };

  const backToLogin = () => {
    navigate("/login");
  };

  return (
    <ForgetPasswordPresentational
      sendEmailClicked={sendEmailClicked}
      email={email}
      setEmail={setEmail}
      btnLoading={btnLoading}
      backToLogin={backToLogin}
    />
  );
};
export default ForgetPasswordFunctional;
