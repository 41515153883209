import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, Transition } from "@headlessui/react";
import { TrashIcon } from "@heroicons/react/24/solid";
const deleteIcon = require("../../../assets/image/icons/delete.svg");

export default function DeletePopup(props) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const {
    className = "",
    warningTitle = t(`COMMON.ARE_YOU_SURE_DELETE`),
    warningText = "",
    conformText = t(`COMMON.CONFIRM`),
    cancelText = t(`COMMON.CANCEL`),
    iconColorClass = "text-red-600",
    onClickConfirm = () => {},
  } = props;
  return (
    <>
      <button
        className={`flex${className ? " " + className : ""}`}
        onClick={() => setIsOpen(true)}
      >
        {/* <TrashIcon className={`h-5 ${iconColorClass}`} /> */}
        <img
          src={deleteIcon.default}
          alt="Delete Icon"
          className="h-[23px] w-[23px] min-h-[23px] min-w-[23px]"
        />
      </button>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setIsOpen(true)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-10 text-center align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h2"
                    className="text-2xl font-medium leading-6 text-gray-500"
                  >
                    {warningTitle}
                  </Dialog.Title>
                  {warningText && (
                    <div className="mt-4">
                      <p className="text-lg text-gray-500">{warningText}</p>
                    </div>
                  )}
                  <div className="mt-8">
                    <button
                      type="button"
                      className="inline-flex justify-center mx-5 rounded-md border border-transparent bg-red-500 px-16 py-3 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                      onClick={(e) => {
                        onClickConfirm();
                        setIsOpen(false);
                      }}
                    >
                      {conformText}
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center mx-5 rounded-md border border-transparent bg-gray-400 px-16 py-3 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                      onClick={() => setIsOpen(false)}
                    >
                      {cancelText}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
