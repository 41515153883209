import React, { useCallback } from "react";
// import { useTranslation } from 'react-i18next'
// import { PhoneIcon, EnvelopeIcon, HandThumbUpIcon, UserGroupIcon, NewspaperIcon, ChatBubbleLeftIcon, } from "@heroicons/react/24/outline";
import Tabs from "@shared/component/tabs/tabs";
import BarChart from "./barchart";
import PieChart from "./piechart";

const DashboardPresentational = ({
  dashboardQueryData,
  setDashboardQueryData,
  dashboardData,
}) => {
  const getCharts = useCallback(
    () => (
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
        <BarChart
          udata={{
            title: "Active Users",
            icon: `/assets/images/icons/user-icon.svg`,
            labels: dashboardData?.activeUserCount?.map((a) => a?.name) || [],
            count: dashboardData?.activeUserCount?.map((a) => a?.count) || [],
          }}
        />
        <PieChart
          udata={{
            title: "Recipes",
            icon: `/assets/images/icons/recipe-icon.svg`,
            data: {
              labels: [`All Users`, `New Users`, `Blocked Users`],
              datasets: [
                {
                  data: [
                    dashboardData?.recipeCount?.activeRecipeCount || 0,
                    dashboardData?.recipeCount?.blockedRecipeCount || 0,
                    dashboardData?.recipeCount?.newRecipeCount || 0,
                  ],
                  backgroundColor: ["#C21F4E", "#F09A53", "#F09A53"],
                },
              ],
            },
          }}
        />
        <PieChart
          udata={{
            title: "Recipes By Tag Group",
            icon: `/assets/images/icons/recipe-icon.svg`,
            data: {
              labels: dashboardData?.tagGroupRecipeCount?.map(
                (a) => a?.tagGroup ?? ""
              ),
              datasets: [
                {
                  data: dashboardData?.tagGroupRecipeCount?.map(
                    (a) => a?.recipeCount ?? 0
                  ),
                  backgroundColor: ["#C21F4E", "#F09A53"],
                },
              ],
            },
          }}
        />
        <PieChart
          udata={{
            title: "Tickets",
            icon: `/assets/images/icons/recipe-icon.svg`,
            data: {
              labels: dashboardData?.ticketStatusCount?.map(
                (a) => a?.status ?? ""
              ),
              datasets: [
                {
                  data: dashboardData?.ticketStatusCount?.map(
                    (a) => a?.count ?? 0
                  ),
                  backgroundColor: ["#C21F4E", "#F09A53"],
                },
              ],
            },
          }}
        />
      </div>
    ),
    [dashboardData]
  );

  const handleTabChange = useCallback((tabIndex) => {
    let chartFilterType = "TODAY";
    switch (tabIndex) {
      case 1: {
        chartFilterType = "YESTER_DAY";
        break;
      }
      case 2: {
        chartFilterType = "THIS_MONTH";
        break;
      }
      case 3: {
        chartFilterType = "LAST_MONTH";
        break;
      }
      case 4: {
        chartFilterType = "ALL_TIME";
        break;
      }
      default: {
        chartFilterType = "TODAY";
        break;
      }
    }
    setDashboardQueryData({
      ...dashboardQueryData,
      chartFilterType: chartFilterType,
      isFilter: true,
    });
  }, []);

  const tabs = [
    {
      tabTitle: "Today",
      tabContent: getCharts(),
    },
    {
      tabTitle: "Yesterday",
      tabContent: getCharts(),
    },
    {
      tabTitle: "This Month",
      tabContent: getCharts(),
    },
    {
      tabTitle: "Last Month",
      tabContent: getCharts(),
    },
    {
      tabTitle: "All Time",
      tabContent: getCharts(),
    },
  ];

  return <Tabs tabsData={tabs} setSelectedIndex={handleTabChange} />;
};

export default DashboardPresentational;
