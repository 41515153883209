import React, { useEffect, useState } from "react";
import "./verification.scss";
import { useLoader } from "@shared/contexts/LoaderContext";
import UserService from "service/user-service";

const AccVerification = () => {
  const { setLoader } = useLoader();
  const queryParamaters = new URLSearchParams(window.location.search);
  const userId = queryParamaters.get("userId");
  const otp = queryParamaters.get("otp");
  const [status, setStatus] = useState(null);

  useEffect(() => {
    if (userId && otp) {
      setLoader(true);
      const payload = {
        userId,
        otp,
      };
      UserService.emailVerification(payload)
        .then((res) => {
          const message = res?.data?.errorMessage;
          switch (message) {
            case "USER_ALREADY_VERIFIED":
              setStatus("User already verified");
              break;
            case "INVALID_USER_ID":
              setStatus("Invalid User");
              break;
            case "EMAIL_VERIFIED":
              setStatus("Email Verified Successfully!");
              break;
            default:
              setStatus("");
              break;
          }
        })
        .catch((e) => {
          if (e?.data?.errorMessage === "WRONG_CODE") {
            setStatus("Wrong Code!");
          } else {
            setStatus("Something went wrong!");
          }
        })
        .finally(() => setLoader(false));
    } else {
      window.location.href = "/login";
    }
  }, []);

  return (
    <div className="verification p-10 md:p-32 flex justify-center items-center overflow-hidden">
      <div className="bg-white p-2 md:p-10 rounded-[10px] flex flex-col items-center shadow-custom-orange">
        <img
          src="/assets/images/qolleqt-logo.svg"
          className="logo my-2 h-[100px] w-[200px]"
          alt="logo"
        />
        <img
          src="/assets/images/icons/mail-icon.png"
          className="mailIcon h-[120px] w-[120px]"
          alt="mail"
        ></img>
        <h1
          style={{ color: "#CF4248", fontSize: "xx-large" }}
          className="text-center my-2"
        >
          {status && status}
        </h1>
        {/* <button className="actionButton">Login</button> */}
      </div>
    </div>
  );
};

export default AccVerification;
