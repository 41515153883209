import { Fragment, useState } from 'react'
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from 'react-redux'
import { useTranslation } from "react-i18next";
import { Dialog, Transition } from '@headlessui/react'
import FormErrMsg from "../../../@shared/component/form-err-msg/form-err-msg";
import PasswordField from "../../../@shared/component/password-field";

const ResetPassword = ({
    passwordResetClicked,
    handlepassword,
    passwordResetForm,
    setPasswordResetForm,
    btnLoading = false,
  }) => {
    
    const { t } = useTranslation();

    const { control, trigger, formState: { errors } } = useForm({
        defaultValues: { userName: null, password: null },
        mode: "all"
    });

    const onSubmit = (e) => {
        e.preventDefault();
        onFormSubmit();
    }

    const onFormSubmit = () => {
        trigger().then((res) => {
            if (res) {
            passwordResetClicked();
            }
        });
    }
    const validatormsg = {
        oldPassword: {
            required: { value: true, message: "VALIDATOR.REQUIRED"},
            minLength: { value: 6, message: "VALIDATOR.MINIMUM.6"}
        },
        newPassword: {
            required: { value: true, message: "VALIDATOR.REQUIRED"},
            minLength: { value: 6, message: "VALIDATOR.MINIMUM.6"}
        },
        confirmPassword: {
            required: { value: true, message: "VALIDATOR.REQUIRED"},
            minLength: { value: 6, message: "VALIDATOR.MINIMUM.6"}
        }
    };

    const dispatch = useDispatch()
    dispatch({type: 'SET_PAGENAME', payload: 'My Profile'})

    // Strt Form Script
    let [isOpen, setIsOpen] = useState(false)

    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }
    return (
        <>
            <button onClick={openModal} className="text-md text-white bg-gradient-to-br from-secondary to-primary rounded-md px-10 py-1 w-2/4 items-center justify-center">
                Reset Password
            </button>
            {/* Strat Popup Form */}
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-full p-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="w-full max-w-2xl p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                        <Dialog.Title
                            as="h2"
                            className="text-lg font-medium leading-6 text-gray-900 border-b pb-2"
                        >
                            Reset Password
                        </Dialog.Title>
                        <div className="my-5">
                            <form onSubmit={onSubmit}>
                                <div className="mb-4">
                                    <Controller
                                        control={control}
                                        name="oldPassword"
                                        rules={validatormsg?.oldPassword}
                                        render={({ field }) => (
                                            <PasswordField
                                            tabIndex="1"
                                            {...field}
                                            name="oldPassword"
                                            placeholder="Enter Old Password"
                                            labelPostion={true}
                                            labelClassName="form-label text-gray-700"
                                            type="text"
                                            value={passwordResetForm?.oldPassword}
                                            lockIcon="true"
                                            onInput={(e) => {
                                                setPasswordResetForm({
                                                ...passwordResetForm,
                                                oldPassword: e.target.value
                                                });
                                                trigger("oldPassword"); 
                                            }}
                                            />
                                        )}
                                    />
                                    <FormErrMsg errors={errors?.oldPassword} />
                                </div>
                                <div className="mb-4">
                                    <Controller
                                    control={control}
                                    name="newPassword"
                                    rules={validatormsg?.newPassword}
                                    render={({ field }) => (
                                        <PasswordField
                                        tabIndex="1"
                                        {...field}
                                        name="newPassword"
                                        placeholder="Enter New Password"
                                        labelPostion={true}
                                        labelClassName="form-label text-gray-700"
                                        type="text"
                                        value={passwordResetForm?.newPassword}
                                        lockIcon="true"
                                        onInput={(e) => {
                                            setPasswordResetForm({
                                            ...passwordResetForm,
                                            newPassword: e.target.value
                                            });
                                            trigger("newPassword"); 
                                        }}
                                        />
                                    )}
                                    />
                                    <FormErrMsg errors={errors?.newPassword} />
                                </div>
                                <div className="mb-4">
                                    <Controller
                                    control={control}
                                    name="confirmPassword"
                                    rules={validatormsg?.confirmPassword}
                                    render={({ field }) => (
                                        <PasswordField
                                        tabIndex="1"
                                        {...field}
                                        name="confirmPassword"
                                        placeholder="Enter Confirm Password"
                                        labelPostion={true}
                                        labelClassName="form-label text-gray-700"
                                        type="text"
                                        value={passwordResetForm?.confirmPassword}
                                        lockIcon="true"
                                        onInput={(e) => {
                                            setPasswordResetForm({
                                            ...passwordResetForm,
                                            confirmPassword: e.target.value
                                            });
                                            trigger("confirmPassword"); 
                                        }}
                                        />
                                    )}
                                    />
                                    <FormErrMsg errors={errors?.confirmPassword} />
                                </div>
                                <div className='text-gray-900 border-t pt-5'>
                                    <span
                                    className="text-md text-white bg-gradient-to-br from-secondary to-primary rounded-md px-10 py-2 items-center justify-center mr-4"
                                    onClick={() => setIsOpen(false)}
                                    >
                                    Cancel
                                    </span>
                                    <button class="text-md  text-white bg-gradient-to-br from-secondary to-primary rounded-md px-10 py-1 items-center justify-center mr-2" 
                                    type="submit"
                                    disabled={
                                    btnLoading ||
                                        setPasswordResetForm.password === null ||
                                        setPasswordResetForm.password === "" ||
                                        setPasswordResetForm.newPassword === "" ||
                                        setPasswordResetForm.newPassword === null ||
                                        setPasswordResetForm.confirmPassword === "" ||
                                        setPasswordResetForm.confirmPassword === null ||
                                        Object.keys(errors).length > 0
                                    }
                                    >
                                    Update
                                    </button>
                                </div>
                            </form>
                        </div>
                        </Dialog.Panel>
                    </Transition.Child>
                    </div>
                </div>
                </Dialog>
            </Transition>
            {/* End Popup Form */}
        </>
    )
}

export default ResetPassword;