import React, { forwardRef, useState } from "react";
import Label from "../label";
import { LockClosedIcon, EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

const PasswordField = forwardRef(
  (
    {
      label = null,
      placeholder = "",
      value,
      labelStyle,
      className = "form-control",
      style,
      labelClassName = "",
      isTooltipContent = null,
      isTooltipIcon = null,
      name,
      isEnterNext = true,
      border,
      isNext,
      min,
      max,
      allowClear = false,
      isRequiredClass = false,
      maxLength,
      desc = null,
      labelPostion = false,
      lockIcon = false,
      ...rest
    },
    ref
  ) => {
    const [showPassword, setShowPassword] = useState(false);

    const tabNext = (e, ref) => {
      if (
        e.key === "Enter" &&
        ref &&
        ref.current &&
        ref.current.input.attributes.tabindex &&
        ref.current.input.attributes.tabindex.value
      ) {
        let curIndex = ref.current.input.attributes.tabindex.value;
        let tabbables = document.querySelectorAll("[tabindex]") || [];
        for (var i = 0; i < tabbables.length; i++) {
          // loop through each element
          if (tabbables[i].tabIndex === parseInt(curIndex) + 1) {
            // check the tabindex to see if it's the element we want
            if (tabbables[i].nodeName === "BUTTON") {
              tabbables[i].click();
            } else {
              tabbables[i].focus(); // if it's the one we want, focus it and exit the loop
            }
            break;
          }
        }
      }
    };

    let defaultInputClass = `input-sm border w-full border-gray-500 rounded-md focus:ring-gray-500 focus:border-gray-500 ${lockIcon ? 'pl-8' : ''}`;
    
    return (
      <div className={`w-full mb-6 md:mb-0${labelPostion ? ' relative' : ''}`}>
        {label && (
          <Label
            style={labelStyle}
            className={labelClassName}
            title={label}
            isTooltipIcon={isTooltipIcon}
            isTooltipContent={isTooltipContent}
            isRequiredClass={isRequiredClass}
            labelPostion={labelPostion}
          />
        )}
        <div className="relative">
            {lockIcon ? (
              <div className="absolute inset-y-0 left-0 pr-3 flex items-center text-sm leading-5">
                <LockClosedIcon className="w-7 h-7 pl-2 text-gray-700"/>
              </div>
            ) : (
              <div></div>
            )}
          
          <input
            {...rest}
            type={showPassword ? 'text' : 'password'}
            style={style}
            ref={ref}
            value={value}
            name={name}
            placeholder={placeholder}
            className={
              defaultInputClass +
              " " +
              className +
              ` ${isRequiredClass ? "required" : ""}`
            }
            onKeyPress={(e) => {
              if (isEnterNext) {
                tabNext(e, ref);
              }
            }}
          />
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5" onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? (
              <EyeSlashIcon className="w-6 h-6 text-gray-700"/>
            ) : (
              <EyeIcon className="w-6 h-6 text-gray-700"/>
            )}
          </div>
        </div>
        {desc && <span className="block text-xs text-gray-400 italic text-light">{desc}</span>}
      </div>
    );
  }
);

export default PasswordField;
