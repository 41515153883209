import { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
// import { useTranslation } from "react-i18next";
import AuthService from "service/auth-service";
// import PropTypes from "prop-types";

function Header(props) {
  const headerHeight = '72px';

  return (
    <div
      className="flex lg:space-x-3 justify-center lg:justify-start lg:px-3 items-center"
      style={{ height: headerHeight }}
    >
      {/* <img src={Logo} /> */}
      <img className="m-auto text-center" src={`/assets/images/qolleqt-logo.svg`} alt={"Logo"} />
    </div>
  );
}

function MenuItem(props) {
  const {
    title,
    to,
    children
  } = props
  let location = useLocation();
  const baseClass = "lg:mx-2 py-4 lg:py-2 lg:px-3 flex justify-center lg:justify-start space-x-4 items-center truncate "
  const noActiveClass = "text-gray-500 lg:rounded-md hover:text-primary hover:bg-primaryLite";
  const activeClass = "lg:rounded-md text-primary bg-primaryLite";

  let subPage = false
  if (to === '/user-management' && location.pathname?.startsWith('/user-profile')) {
    subPage = true
  } else if (to === '/recipe-management' && location.pathname?.startsWith('/recipe-info')) {
    subPage = true
  }

  return (
    <NavLink
      to={to}
      replace
      className={({ isActive }) => (isActive || subPage) ? baseClass + activeClass : baseClass + noActiveClass}
      title={title}
    >
      {children}
      <span className="hidden lg:inline">{title}</span>
    </NavLink>
  );
}

const SideMenu = () => {
  let location = useLocation();
  // const { t } = useTranslation();
  const CurrentUser = AuthService.getCurrentUser();

  // useEffect(() => {
  //   return
  // }, [location]);

  const itemIconClass = "w-8 h-8 lg:w-5 lg:h-5";

  return (
    <div className="shadow bg-white overflow-y-auto h-screen">
      <Header title="Qolleqt" />
      {CurrentUser?.menuPermissions?.length > 0 && (
        <ul className="lg:mt-2 lg:space-y-2">
          {CurrentUser?.menuPermissions?.map((menuItem, index) => {
            return (
              <MenuItem key={index} to={menuItem?.actionUrl} title={menuItem?.name}>
                <img src={`/assets/images/icons/${location.pathname === menuItem?.actionUrl ? `${menuItem?.icon}-active` : `${menuItem?.icon}`}.svg`} className={itemIconClass} alt={menuItem?.name} />
              </MenuItem>
            )
          })}
        </ul>
      )}
    </div>
  );
}

// Header.propTypes = {
//   title: PropTypes.string.isRequired,
// };

// MenuItem.propTypes = {
//   title: PropTypes.string.isRequired,
//   active: PropTypes.bool,
// };

SideMenu.MenuItem = MenuItem;
SideMenu.Header = Header;

export default SideMenu;
