import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate, Navigate } from "react-router-dom";
import { useToastContext } from "../@shared/contexts/ToastContext";
import AuthService from "../service/auth-service";
import Roles from "../@shared/utils/role";
import AdminLayout from "../@layouts/admin-layout/layout";

const PrivateRoute = () => {
  const navigate = useNavigate();
  const addToast = useToastContext();
  const authData = useSelector((state) => state?.AuthReducer) || null;
  useEffect(() => {
    const CurrentUser = AuthService.getCurrentUser();
    // not logged in so redirect to login page with the return url
    if (!CurrentUser) {
      navigate("/login");
    } else {
      // check if route is restricted by role
      if (
        ![Roles?.SUPER_ADMIN, Roles?.ADMIN].includes(CurrentUser?.role?.name)
      ) {
        AuthService?.logout()?.then(() => {
          addToast({ toast: `COMMON.ACCESS_DENIED` });
        });
      }
    }
  }, [navigate, addToast, authData]);

  return <AdminLayout component={Outlet} />;
};
export default PrivateRoute;
