import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useToastContext } from "@shared/contexts/ToastContext";
import { useLoader } from "@shared/contexts/LoaderContext";
import UserService from "service/user-service";
import UserManagementPresentational from "./user-management-presentational";

const UserManagementFunctional = () => {
  const addToast = useToastContext();
  const { setLoader } = useLoader();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    dispatch({ type: "SET_PAGENAME", payload: t(`COMMON.USER_MGMT`) });
  }, [dispatch, t]);

  const defulatUserQueryData = {
    search: "",
    page: 0,
    size: 10,
    roleIds: 3,
    sort: "desc",
    roles: "USER",
    isFilter: true,
    isSearched: false,
  };

  const [userQueryData, setUserQueryData] = useState(defulatUserQueryData);
  const [userListData, setUserListData] = useState(null);
  const [isUserListEmpty, setIsUserListEmpty] = useState(false);

  const fetchUserList = useCallback(
    (params) => {
      setLoader(true);
      UserService.getUserList(params)
        .then((userListResponse) => {
          if (parseInt(userListResponse.status, 0) === 204) {
            setIsUserListEmpty(true);
            setUserListData({});
            // addToast({toast: `COMMON.NO_USERS_FOUND`});
          } else {
            // console.log(userListResponse?.data)
            setIsUserListEmpty(false);
            setUserListData(userListResponse?.data);
          }
        })
        .catch((e) => {
          addToast({ toast: e });
        })
        .finally(() => setLoader(false));
    },
    [addToast]
  );

  const updateUserStatus = (id, status) => {
    // console.log(id, status)
    setLoader(true);
    let userStatus = "";
    if (status == "ACTIVE") {
      userStatus = "SUSPENDED";
    }
    if (status == "SUSPENDED") {
      userStatus = "ACTIVE";
    }
    UserService.updateUserStatus(id, userStatus)
      .then((userStateUpdateResponse) => {
        if (parseInt(userStateUpdateResponse.status, 0) === 204) {
          addToast({ toast: `COMMON.NO_DATA_FOUND` });
        } else {
          fetchUserList(userQueryData);
          addToast({
            toast: `TOASTR.COMMON.USER_STATE_CHANGED`,
            type: "success",
          });
        }
      })
      .catch((e) => {
        addToast({ toast: e });
      })
      .finally(() => setLoader(false));
  };

  const deleteUser = (id) => {
    setLoader(true);
    UserService.deleteUser(id)
      .then((userDeleteResponse) => {
        if (parseInt(userDeleteResponse.status, 0) === 204) {
          addToast({ toast: `COMMON.NO_DATA_FOUND` });
        } else {
          const maxPageWillAvail =
            Math.floor(
              (userListData?.page?.totalElements - 1) / userListData?.page?.size
            ) +
            ((userListData?.page?.totalElements - 1) %
              userListData?.page?.size >
              0
              ? 1
              : 0);
          if (Number(userListData?.page?.number) >= maxPageWillAvail) {
            setUserQueryData({ ...userQueryData, page: maxPageWillAvail - 1 });
          } else {
            fetchUserList(userQueryData);
          }
          addToast({
            toast: `TOASTR.COMMON.USER_DELETE_SUCCESS`,
            type: "success",
          });
        }
      })
      .catch((e) => {
        addToast({ toast: e });
      })
      .finally(() => setLoader(false));
  };

  const searchUsers = (searchText) => {
    if (
      searchText?.length >= 3 &&
      (!isUserListEmpty || userQueryData?.search?.length >= searchText.length)
    ) {
      setUserQueryData({ ...userQueryData, search: searchText, page: 0 });
    }
    if (searchText?.length === 0 && userQueryData?.search?.length > 0)
      setUserQueryData({ ...userQueryData, search: "" });
  };

  useEffect(() => {
    if (userQueryData?.isFilter) {
      setUserQueryData({ ...userQueryData, isFilter: false })
      fetchUserList(userQueryData);
    }
  }, [userQueryData]);

  return (
    <UserManagementPresentational
      isUserListEmpty={isUserListEmpty}
      userQueryData={userQueryData}
      setUserQueryData={setUserQueryData}
      userListData={userListData}
      updateUserStatus={updateUserStatus}
      deleteUser={deleteUser}
      searchUsers={searchUsers}
    />
  );
};
export default UserManagementFunctional;
