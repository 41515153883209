import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useToastContext } from "@shared/contexts/ToastContext";
import { useLoader } from "@shared/contexts/LoaderContext";
import UserService from "service/user-service";
import DashboardPresentational from "./dashboard-presentational";

const DashboardFunctional = () => {
  const addToast = useToastContext();
  const { setLoader } = useLoader();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    dispatch({ type: "SET_PAGENAME", payload: t(`COMMON.DASHBOARD`) });
  }, [dispatch, t]);

  const defulatDashboardQueryData = {
    chartFilterType: "TODAY",
    isFilter: true,
  };

  const [dashboardQueryData, setDashboardQueryData] = useState(
    defulatDashboardQueryData
  );
  const [dashboardData, setDashboardData] = useState({});

  const fetchUserDasboard = useCallback(
    (dashboardQueryData) => {
      setLoader(true);
      UserService.getCurrentUserDashboard(dashboardQueryData)
        .then((userDashboardResponse) => {
          if (parseInt(userDashboardResponse.status, 0) === 204) {
            // addToast({toast: `COMMON.NO_DATA_FOUND`});
          } else {
            // console.log(userDashboardResponse?.data)
            setDashboardData(userDashboardResponse?.data);
          }
        })
        .catch((e) => {
          addToast({ toast: e });
        })
        .finally(() => setLoader(false));
    },
    [addToast]
  );

  const fetchNotificationsCount = useCallback(() => {
    setLoader(true);
    UserService.getNotificationCount().then((res) => {
      dispatch({
        type: "SET_NOTIFICATION_COUNT",
        payload: res?.data?.count ?? 0,
      });
    });
  }, []);

  useEffect(() => {
    if (dashboardQueryData?.isFilter) {
      setDashboardQueryData({ ...dashboardQueryData, isFilter: false });
      fetchUserDasboard(dashboardQueryData);
      fetchNotificationsCount();
    }
  }, [dashboardQueryData]);

  return (
    <DashboardPresentational
      dashboardQueryData={dashboardQueryData}
      setDashboardQueryData={setDashboardQueryData}
      dashboardData={dashboardData}
    />
  );
};
export default DashboardFunctional;
