import React from "react";
// import {
//   Popover,
//   PopoverHandler,
//   PopoverContent,
// } from "@material-tailwind/react";
import { Popover } from '@headlessui/react'
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
// import SVG from "assets/image/svg";
// import "./vt-label.scss";

const Label = ({
  className = "",
  title = "",
  children = "",
  isTooltipIcon = null,
  isTooltipContent = null,
  isRequiredClass = false,
  onClick,
  labelPostion,
  style
}) => {
  const defaultLabelClasses = "form-label uppercase tracking-wide text-gray-700 text-xs font-semibold mb-2 leading-none	"
  const requiredClass = isRequiredClass ? " after:content-['*'] after:ml-0.5 after:text-red-500" : ""
  const labelPostionClass = labelPostion ? " absolute t-0 -translate-y-1/2 bg-white z-10 mx-4 px-2 leading-none	" : ""
  return (
    <div className={`block${labelPostionClass}`} style={style}>
      <label className={(className || defaultLabelClasses) + requiredClass} onClick={onClick}>
        {title ? title : children}
      </label>
      {isTooltipContent && (
        <div className="inline-block">
          <Popover className="relative">
            <Popover.Button>
              <span className="isTooltipContent inline-block align-text-bottom">
                {isTooltipIcon ? (
                  <img
                    height="14"
                    width="14"
                    src={isTooltipIcon}
                    alt="TooltipIcon"
                  />
                ) : (
                  <QuestionMarkCircleIcon className="inline-block h-4 w-4"/>
                )}
              </span>
            </Popover.Button>
            <Popover.Panel  className="absolute bg-white bottom-100 left-1/2 z-10 mt-3 w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
              {isTooltipContent}
            </Popover.Panel>
          </Popover>
        </div>
      )}
    </div>
  );
};
export default Label;
