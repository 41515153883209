import React, { forwardRef } from "react";
import Label from "../label";

const SimpleInput = forwardRef(({ desc = null, ...restProps }, ref) => {
  return (
    <>
      <input ref={ref} {...restProps} />
      {desc && <span className="block text-xs text-gray-400 italic text-light">{desc}</span>}
    </>
  );
} )

const TextField = forwardRef(
  (
    {
      placeholder = "",
      value,
      labelStyle,
      className = "form-control",
      style,
      type = "text",
      label = null,
      labelClassName = "",
      isTooltipContent = null,
      isTooltipIcon = null,
      name,
      isEnterNext = true,
      border,
      isNext,
      min,
      max,
      allowClear = false,
      isRequiredClass = false,
      maxLength,
      desc = null,
      labelPostion = false,
      ...rest
    },
    ref
  ) => {
    const tabNext = (e, ref) => {
      if (
        e.key === "Enter" &&
        ref &&
        ref.current &&
        ref.current.input.attributes.tabindex &&
        ref.current.input.attributes.tabindex.value
      ) {
        let curIndex = ref.current.input.attributes.tabindex.value;
        let tabbables = document.querySelectorAll("[tabindex]") || [];
        for (var i = 0; i < tabbables.length; i++) {
          // loop through each element
          if (tabbables[i].tabIndex === parseInt(curIndex) + 1) {
            // check the tabindex to see if it's the element we want
            if (tabbables[i].nodeName === "BUTTON") {
              tabbables[i].click();
            } else {
              tabbables[i].focus(); // if it's the one we want, focus it and exit the loop
            }
            break;
          }
        }
      }
    };

    let defaultInputClass = "input-sm border w-full border-gray-500 rounded-md focus:ring-gray-500 focus:border-gray-500";

    const getRenderer = () => {
      switch (type) {
        case "text":
          return (
            <SimpleInput
              {...rest}
              type="text"
              style={style}
              ref={ref}
              value={value}
              name={name}
              placeholder={placeholder}
              className={
                defaultInputClass +
                " " +
                className +
                ` ${isRequiredClass ? "required" : ""}`
              }
              onKeyPress={(e) => {
                if (isEnterNext) {
                  tabNext(e, ref);
                }
              }}
            />
          );
        case "phoneNumber":
          return (
            <SimpleInput
              ref={ref}
              {...rest}
              type="tel"
              pattern="[0-9]*"
              maxLength={maxLength}
              style={style}
              value={value}
              name={name}
              placeholder={placeholder}
              className={
                defaultInputClass +
                " " +
                className +
                ` ${isRequiredClass ? "required" : ""}`
              }
              onKeyPress={(e) => {
                if (isNext) {
                  tabNext(e, ref);
                }
              }}
            />
          );
        case "email":
          return (
            <SimpleInput
              style={style}
              {...rest}
              name={name}
              type="email"
              ref={ref}
              placeholder={placeholder}
              className={
                defaultInputClass +
                " " +
                className +
                ` ${isRequiredClass ? "required" : ""}`
              }
              value={value}
              onKeyPress={(e) => {
                if (isNext) {
                  tabNext(e, ref);
                }
              }}
            />
          );
        case "number":
          return (
            <SimpleInput
              {...rest}
              style={style}
              name={name}
              value={value}
              min={min}
              max={max}
              type="number"
              pattern={/[+-]?([0-9]*[.])?[0-9]+/}
              ref={ref}
              placeholder={placeholder}
              className={
                defaultInputClass +
                " " +
                className +
                ` ${isRequiredClass ? "required" : ""}`
              }
              onKeyPress={(e) => {
                if (isNext) {
                  tabNext(e, ref);
                }
              }}
            />
          );
        default:
          return (
            <SimpleInput
              {...rest}
              type={type || 'text'}
              style={style}
              ref={ref}
              value={value}
              name={name}
              className={
                defaultInputClass +
                " " +
                className +
                ` ${isRequiredClass ? "required" : ""}`
              }
              onKeyPress={(e) => {
                if (isNext) {
                  tabNext(e, ref);
                }
              }}
            />
          );
      }
    };

    return (
      <div className={`w-full mb-6 md:mb-0${labelPostion ? ' relative' : ''}`}>
        {label && (
          <Label
            style={labelStyle}
            className={labelClassName}
            title={label}
            isTooltipIcon={isTooltipIcon}
            isTooltipContent={isTooltipContent}
            isRequiredClass={isRequiredClass}
            labelPostion={labelPostion}
          />
        )}
        {getRenderer()}
      </div>
    );
  }
);

export default TextField;
