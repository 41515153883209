import React from 'react';
import { useTranslation } from "react-i18next";
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { usePagination, DOTS } from './usePagination';
const Pagination = props => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;

  const { t } = useTranslation();
  const currentPageRealNumber = Number(currentPage || 0) + 1
  const paginationRange = usePagination({
    totalCount,
    pageSize,
    siblingCount,
    currentPage: currentPageRealNumber
  });

  if (currentPageRealNumber === 0 || paginationRange?.length < 2) {
    return null;
  }

  let lastPage = paginationRange?.[Number(paginationRange?.length || 1) - 1];

  const onNext = () => {
    if( currentPageRealNumber < lastPage ) {
      onPageChange(currentPage + 1);
    }
  };

  const onPrevious = () => {
    if( currentPageRealNumber > 1 ) {
      onPageChange(currentPage - 1);
    }
  };

  return (
    <nav className="isolate flex flex-wrap" aria-label="Pagination">
      <button
        onClick={onPrevious}
        className="relative flex items-center justify-center text-center leading-none rounded-md m-1 border border-primary bg-primaryLite px-1 py-1 text-sm font-medium text-primary hover:text-white hover:bg-primary disabled:text-gray-500 disabled:bg-gray-300 disabled:border-gray-300 disabled:opacity-40"
        disabled={currentPageRealNumber === 1 || false}
      >
        <span className="sr-only">{t('COMMON.PREVIOUS')}</span>
        <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
      </button>
      {paginationRange?.map((pageNumber, key) => {
        if (pageNumber === DOTS) {
          return (
            <span key={key} className="flex items-center justify-center text-center leading-none rounded-md m-1 border border-primary bg-primaryLite px-2.5 py-1 text-sm font-medium text-primary opacity-50">
              &#8230;
            </span>
          )
        }
        return (
          <button
            key={key}
            className={`flex items-center justify-center text-center leading-none rounded-md m-1 border px-2.5 py-1 text-sm font-medium ${pageNumber === currentPageRealNumber ? 'text-white border-primary bg-primary' : 'border-primary bg-primaryLite px-1 py-1 text-sm font-medium text-primary hover:text-white hover:bg-primary'}`}
            onClick={() => onPageChange(Number(pageNumber || 1) - 1)}
            disabled={currentPageRealNumber === pageNumber || false}
          >
            {pageNumber}
          </button>
        );
      })}
      <button
        onClick={onNext}
        className="relative flex items-center justify-center text-center leading-none rounded-md m-1 border border-primary bg-primaryLite px-1 py-1 text-sm font-medium text-primary hover:text-white hover:bg-primary disabled:text-gray-500 disabled:bg-gray-300 disabled:border-gray-300 disabled:opacity-40"
        disabled={currentPageRealNumber === lastPage || false}
      >
        <span className="sr-only">{t('COMMON.NEXT')}</span>
        <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
      </button>
    </nav>
  )
};

export default Pagination;
