import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  usePagination,
} from "react-table";
// import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
// import { Button, PageButton } from '../button/button'
import { classNames } from "../utils/utils";
import { SortIcon } from "../icons/icons";
import Pagination from "./pagination";

// Define a default UI for filtering
const GlobalFilter = ({ globalFilter, setGlobalFilter, count }) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || "");
  }, 200);

  // return (
  //   <div className="relative rounded-md overflow-hidden">
  //     <span className="absolute bg-gradient-primary text-white inset-y-0 left-0 flex items-center px-2">
  //       <MagnifyingGlassIcon className="w-5 h-5" />
  //     </span>
  //     <input
  //       type="search"
  //       className="py-2 text-sm text-gray-500 bg-white border-primary rounded-md pl-12 w-80 max-w-full focus:outline-none focus:ring-primary focus:border-primary"
  //       value={value || ""}
  //       onChange={(e) => {
  //         setValue(e.target.value);
  //         onChange(e.target.value);
  //       }}
  //       placeholder={`${count} ${t("COMMON.RECORDS")}`}
  //       autoComplete="off"
  //     />
  //   </div>
  // );
};

// This is a custom filter UI for selecting
// a unique option from a list
export const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id, render },
}) => {
  const { t } = useTranslation();
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <label className="flex gap-x-2 items-baseline">
      <span className="text-gray-700">{render("Header")}: </span>
      <select
        className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        name={id}
        id={id}
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
      >
        <option value="">{t("COMMON.ALL")}</option>
        {options.map((option, i) => (
          <option key={i} value={option}>
            {option}
          </option>
        ))}
      </select>
    </label>
  );
};

export const StatusPill = ({ value }) => {
  const status = value ? value.toLowerCase() : "unknown";

  return (
    <span
      className={classNames(
        "px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm",
        status.startsWith("active") ? "bg-green-100 text-green-800" : null,
        status.startsWith("inactive") ? "bg-yellow-100 text-yellow-800" : null,
        status.startsWith("offline") ? "bg-red-100 text-red-800" : null
      )}
    >
      {status}
    </span>
  );
};

export const AvatarCell = ({ value, column, row }) => {
  const navigate = useNavigate();
  const Link = ({ children }) => {
    if (column?.AvatarCellLink && row?.original?.[column?.AvatarCellLink]) {
      return (
        <button
          className="flex"
          onClick={() => navigate(row?.original?.[column?.AvatarCellLink])}
        >
          {children}
        </button>
      );
    }
    return children;
  };
  return (
    <Link>
      <div className="flex items-center">
        {row?.original?.[column?.imgAccessor] && (
          <div className="flex-shrink-0 h-10 w-10 mr-4">
            <img
              className="h-10 w-10 rounded-full"
              src={row?.original?.[column?.imgAccessor]}
              alt={value}
            />
          </div>
        )}
        <div>
          <div className="text-sm font-medium text-gray-900 whitespace-pre">
            {value}
          </div>
          <div className="text-sm text-gray-500">
            {row?.original?.[column?.emailAccessor]}
          </div>
        </div>
      </div>
    </Link>
  );
};

const Table = (props) => {
  const { t } = useTranslation();
  const {
    columns,
    data,
    tablePageData,
    queryData,
    setQueryData,
    searchContent,
    isNoData = false,
    noDataText = t(`COMMON.NO_DATA_FOUND`),
    disableSearch = false,
    SearchFields = <></>,
    onSortChange = () => {},
  } = props;
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // rows,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    // canPreviousPage,
    // canNextPage,
    // pageOptions,
    // pageCount,
    // gotoPage,
    // nextPage,
    // previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    // setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      manualPagination: false,
      pageSize: Number(tablePageData?.size) || 10,
      pageIndex: Number(tablePageData?.number) || 0,
      pageCount: Number(tablePageData?.totalPages) || 1,
      manualSortBy: true,
    },
    useFilters, // useFilters!
    useGlobalFilter,
    useSortBy,
    usePagination // new
  );

  useEffect(() => {
    // console.log(state.sortBy)
    onSortChange(state.sortBy);
  }, [state.sortBy]);

  // const canNextPage = ((Number(tablePageData?.number) + 1) < Number(tablePageData?.totalPages)) || false
  // const canPreviousPage = ((Number(tablePageData?.number) + 1) > 1) || false

  // const previousPage = () => {
  //   setQueryData({...queryData, page: Number(tablePageData?.number)-1})
  // }

  // const nextPage = () => {
  //   setQueryData({...queryData, page: Number(tablePageData?.number)+1})
  // }

  const gotoPage = (pageNum) => {
    let newQueryData = {
      ...queryData,
      page: pageNum,
    };
    if (queryData?.isFilter === false) {
      newQueryData.isFilter = true;
    }
    setQueryData({ ...newQueryData });
  };

  // Render the UI for your table
  return (
    <>
      <div className="sm:flex sm:gap-x-2">
        {disableSearch || (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={searchContent}
            count={tablePageData?.totalElements || 0}
          />
        )}
        {headerGroups.map((headerGroup) =>
          headerGroup.headers.map((column) =>
            column.Filter ? (
              <div className="mt-2 sm:mt-0" key={column.id}>
                {column.render("Filter")}
              </div>
            ) : null
          )
        )}
      </div>
      {isNoData ? (
        <div className="py-3 h-full flex items-center justify-center">
          {noDataText}
        </div>
      ) : (
        <>
          {/* table */}
          <div className="flex flex-col">
            <div className="align-middle inline-block min-w-full">
              <div className="border-b border-gray-200 px-px py-1">
                {SearchFields}
                <div className="overflow-x-auto">
                  <table
                    {...getTableProps()}
                    className="min-w-full divide-y divide-gray-200 overflow-hidden"
                  >
                    <thead className="bg-gray-50">
                      {headerGroups.map((headerGroup) => (
                        <tr
                          {...headerGroup.getHeaderGroupProps()}
                          className="bg-white"
                        >
                          {headerGroup.headers.map((column) => (
                            // Add the sorting props to control sorting. For this example
                            // we can add them into the header props
                            <th
                              scope="col"
                              className="group px-3 2xl:px-6 py-2 2xl:py-3 text-left text-xs font-medium text-gray-500 tracking-wider"
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              <div className="flex items-center gap-2">
                                {column.render("Header")}
                                {/* Add a sort direction indicator */}
                                <span>
                                  {column.canSort && (
                                    <SortIcon
                                      className="w-4 h-4"
                                      upClassName={
                                        column.isSorted && column.isSortedDesc
                                          ? `fill-[#F48220]`
                                          : `fill-[#BCBCBC]`
                                      }
                                      downClassName={
                                        column.isSorted && !column.isSortedDesc
                                          ? `fill-[#F48220]`
                                          : `fill-[#BCBCBC]`
                                      }
                                    />
                                  )}
                                </span>
                              </div>
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody
                      {...getTableBodyProps()}
                      className="bg-white divide-y divide-gray-200"
                    >
                      {page.map((row, i) => {
                        // new
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                <td
                                  {...cell.getCellProps()}
                                  className={`bg-[#F5F5F5] px-3 2xl:px-6 py-2 2xl:py-3 break-words break-word`}
                                  role="cell"
                                >
                                  {cell.column.Cell.name ===
                                  "defaultRenderer" ? (
                                    <div className="text-sm text-gray-500">
                                      {cell.render("Cell")}
                                    </div>
                                  ) : (
                                    cell.render("Cell")
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* Pagination */}
          {Number(tablePageData?.totalElements) >
            Number(tablePageData?.size) && (
            <div className="py-3 flex items-center justify-between">
              {/* <div className="sm:flex-1 sm:flex sm:items-center sm:justify-between"> */}
              <div className="sm:flex-1 sm:flex sm:items-center sm:justify-center">
                {/* <div className="hidden sm:flex gap-x-2 items-baseline">
                  <span className="text-sm text-gray-700">
                    {t("COMMON.PAGE")}{" "}
                    <span className="font-medium">
                      {Number(tablePageData?.number) + 1 || 1}
                    </span>{" "}
                    {t("COMMON.OF")}{" "}
                    <span className="font-medium">
                      {tablePageData?.totalPages || 1}
                    </span>
                  </span>
                  <label>
                    <span className="sr-only">{t("COMMON.ITEMS_PER_PAGE")}</span>
                    <select
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      value={Number(tablePageData?.size) || 10}
                      onChange={(e) => {
                        let currentPage = Number(tablePageData?.number) + 1 || 1;
                        if (e.target.value < Number(tablePageData?.size) || 10) {
                          const maxPageWillAvail =
                            Math.floor(
                              tablePageData?.totalElements / e.target.value
                            ) +
                            (tablePageData?.totalElements % e.target.value > 0
                              ? 1
                              : 0);
                          currentPage =
                            currentPage > maxPageWillAvail
                              ? maxPageWillAvail
                              : currentPage;
                        }
                        setQueryData({
                          ...queryData,
                          size: Number(e.target.value),
                          page: currentPage - 1,
                        });
                        setPageSize(Number(e.target.value));
                      }}
                    >
                      {[5, 10, 20].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          {t("COMMON.SHOW")} {pageSize}
                        </option>
                      ))}
                    </select>
                  </label>
                </div> */}
                <div>
                  <Pagination
                    onPageChange={gotoPage}
                    totalCount={tablePageData?.totalElements}
                    currentPage={Number(tablePageData?.number) || 0}
                    pageSize={Number(tablePageData?.size) || 10}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Table;
