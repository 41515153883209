import axios from "axios";
import { LoginUrl } from "./url";

const login = async (request, headers = {}, params = {}) => {
  return axios.post(LoginUrl.LOG_IN, request, {
    headers: headers,
    params: params
  });
};

const refreshToken = async (refreshToken) => {
  const headers = {
    'refreshToken': refreshToken,
    'skipAuth': true,
  }
  return axios.post(LoginUrl.REFRESH_TOKEN, {}, {
    headers: headers,
    params: {}
  });
};

const forgetPassword = async (request, headers = {}, params = {}) => {
  return axios.put(LoginUrl.FORGET_PASSWORD, request, {
    headers: headers,
    params: params
  });
};

const resetPassword = async (request) => {
  return axios.put(LoginUrl.RESET_PASSWORD, request);
};

const logout = async (token = false) => {
  const headers = {
    skipErrors: true
  }
  if( token ) {
    headers['Authorization'] = `Bearer ${token}`
    headers['skipAuth'] = true
  }
  return axios.get(LoginUrl.LOGOUT, {
    headers: headers,
    params: {}
  });
};

const LoginService = {
  login,
  logout,
  refreshToken,
  forgetPassword,
  resetPassword,
};

export default LoginService;
