import store from '../@shared/store/create-store';
// import { redirect } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import LoginService from "./login-service";

const updateTokenState = (token = null) => {
  store?.dispatch({type: 'SET_ACCESS_TOKEN', payload: token})
}

const setToken = async (token) => {
  secureLocalStorage.setItem("CURRENT_SESSION", new Date().getTime())
  secureLocalStorage.setItem("ACCESS_TOKEN", token)
  updateTokenState(token)
};

const getToken = () => {
  return secureLocalStorage.getItem("ACCESS_TOKEN")
};

const removeToken = () => {
  secureLocalStorage.removeItem("ACCESS_TOKEN");
};

const setRefreshToken = (token) => {
  secureLocalStorage.setItem("REFRESH_TOKEN", token)
};

const getRefreshToken = () => {
  return secureLocalStorage.getItem("REFRESH_TOKEN")
};

const removeRefreshToken = () => {
  secureLocalStorage.removeItem("REFRESH_TOKEN");
};

const verifyActiveSession = () => {
  let expires = 24
  const CurrentSession = secureLocalStorage.getItem('CURRENT_SESSION')
  if (!CurrentSession || (CurrentSession && (new Date().getTime() - CurrentSession > expires * 60 * 60 * 1000))) {
    return false
  }
  return true
};

const setCurrentUser = (data) => {
  secureLocalStorage.setItem("CURRENT_USER", data)
};

const getCurrentUser = () => {
  return secureLocalStorage.getItem("CURRENT_USER")
};

const removeCurrentUser = () => {
  secureLocalStorage.removeItem("CURRENT_USER");
};

const clearSecureLocalStorage = async () => {
  secureLocalStorage.clear()
  updateTokenState()
  localStorage.setItem("lng", "en");
}

const updateToken = async () => {
  const refreshToken = await getRefreshToken();
  try {
    const refreshResponse = await LoginService?.refreshToken(refreshToken);
    if(refreshResponse?.data?.accessToken) {
      setToken(refreshResponse?.data?.accessToken);
      updateTokenState(refreshResponse?.data?.accessToken)
      if(refreshResponse?.data?.refreshToken) {
        setRefreshToken(refreshResponse?.data?.refreshToken);
      }
      return refreshResponse?.data?.accessToken
    }
    clearSecureLocalStorage()
  } catch (e) {
    clearSecureLocalStorage()
  }
};

const logout = async () => {
  try {
    await LoginService?.logout()
  } catch (e) {
  } finally {
    clearSecureLocalStorage()
  }
};

const AuthService = {
  setToken,
  getToken,
  removeToken,
  updateToken,
  setRefreshToken,
  getRefreshToken,
  removeRefreshToken,
  verifyActiveSession,
  setCurrentUser,
  getCurrentUser,
  removeCurrentUser,
  clearSecureLocalStorage,
  logout,
};

export default AuthService;
