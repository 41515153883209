// let apiUrl = "http://44.242.77.176";
let apiUrl = process.env.REACT_APP_API_BASE;
// console.log(apiUrl)
// if (!apiUrl) {
//   // apiUrl = "http://44.242.77.176";
//   apiUrl = "http://localhost";
// }
let apiUrls = {
  // user: `${apiUrl}:6060/qolleqt-user/api/v1`,
  // recipe: `${apiUrl}:6061/qolleqt-recipe/api/v1`,
  // template: `${apiUrl}:6062/qolleqt-template/api/v1`,
  // attachment: `${apiUrl}:6063/qolleqt-attachment/api/v1`,
  user: `${apiUrl}/qolleqt-user/api/v1`,
  recipe: `${apiUrl}/qolleqt-recipe/api/v1`,
  template: `${apiUrl}/qolleqt-template/api/v1`,
  attachment: `${apiUrl}/qolleqt-attachment/api/v1`,
};

let apiAuthUrl = "";
// if (process.env.REACT_APP_ENV === "production") {
//   apiUrl = "http://127.0.0.1:8000";
//   apiAuthUrl = "http://127.0.0.1:8000";
// } else {
//   apiUrl = "http://127.0.0.1:8000";
//   apiAuthUrl = "http://127.0.0.1:8000";
// }

const URLs = {
  apiUrl,
  apiUrls,
  apiAuthUrl,
};
// apiUrl = "https://cors-anywhere.herokuapp.com/http://122.165.203.72:6061/vitrum-api";

export default URLs;
