import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useToastContext } from "@shared/contexts/ToastContext";
import { useLoader } from "@shared/contexts/LoaderContext";
import RecipeService from "service/recipe-service";
import CommentService from "service/comment-service";
import RecipeInfoPresentational from "./recipe-info-presentational";

const RecipeInfoFunctional = () => {
  const { id } = useParams();
  const getId = id;
  const addToast = useToastContext();
  const { setLoader } = useLoader();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    dispatch({ type: "SET_PAGENAME", payload: t(`COMMON.RECIPE_INFO`) });
  }, [dispatch, t]);

  const defulatCommentQueryData = {
    page: 0,
    size: 10,
    sort: "desc",
    isFilter: true,
    isSearched: false,
  };

  const [tabIndex, setTabIndex] = useState(0);
  const [isDescriptionModelOpen, setIsDescriptionModelOpen] = useState(false);

  const [recipeData, setRecipeData] = useState(null);
  const [commentQueryData, setCommentQueryData] = useState(
    defulatCommentQueryData
  );
  const [subsQueryData, setSubsQueryData] = useState(defulatCommentQueryData);
  const [commentListData, setCommentListData] = useState(null);
  const [isCommentListEmpty, setIsCommentListEmpty] = useState(false);
  const [substitutionData, setSubstitutionData] = useState(null);
  const [issubstitutionEmpty, setIssubstitutionEmpty] = useState(false);

  const totalIntialCall = 2;
  let completedCalls = 0;

  const fetchRecipe = useCallback(
    (id) => {
      setLoader(true);
      RecipeService.getRecipe(id)
        .then((recipeResponse) => {
          if (parseInt(recipeResponse.status, 0) === 204) {
            // addToast({toast: `COMMON.NO_DATA_FOUND`});
          } else {
            // console.log(recipeResponse?.data)
            setRecipeData(recipeResponse?.data);
          }
        })
        .catch((e) => {
          addToast({ toast: e });
        })
        .finally(() => {
          if (completedCalls + 1 < totalIntialCall) {
            completedCalls++;
          } else {
            setLoader(false);
          }
        });
    },
    [addToast]
  );

  const fetchRecipeCommentList = useCallback(
    (id, queryData) => {
      setLoader(true);
      CommentService.getRecipeCommentList(id, queryData)
        .then((commentListResponse) => {
          if ([200, 204]?.includes?.(parseInt(commentListResponse.status, 0))) {
            if (parseInt(commentListResponse.status, 0) === 204) {
              setIsCommentListEmpty(true);
              setCommentListData({});
              // addToast({toast: `COMMON.NO_COMMENTS_FOUND`});
            } else {
              // console.log(commentListResponse?.data)
              setIsCommentListEmpty(false);
              setCommentListData(commentListResponse?.data);
            }
          } else {
            addToast({
              toast: commentListResponse?.data?.errors?.[0]?.message,
            });
          }
        })
        .catch((e) => {
          addToast({ toast: e });
        })
        .finally(() => {
          if (completedCalls + 1 < totalIntialCall) {
            completedCalls++;
          } else {
            setLoader(false);
          }
        });
    },
    [addToast]
  );

  const fetchRecipeSubstitutionData = useCallback(
    (id, queryData) => {
      setLoader(true);
      CommentService.getSubstitutionData(id, queryData)
        .then((substitutionResponse) => {
          if (parseInt(substitutionResponse.status, 0) === 204) {
            setIssubstitutionEmpty(true);
            setSubstitutionData({});
            // addToast({toast: `COMMON.NO_COMMENTS_FOUND`});
          } else {
            // console.log(commentListResponse?.data)
            setIssubstitutionEmpty(false);
            setSubstitutionData(substitutionResponse?.data);
          }
        })
        .catch((e) => {
          addToast({ toast: e });
        })
        .finally(() => {
          if (completedCalls + 1 < totalIntialCall) {
            completedCalls++;
          } else {
            setLoader(false);
          }
        });
    },
    [addToast]
  );

  const deleteComment = (id) => {
    setLoader(true);
    CommentService.deleteComment(id)
      .then((commentDeleteResponse) => {
        if (parseInt(commentDeleteResponse.status, 0) === 204) {
          addToast({ toast: `COMMON.NO_DATA_FOUND` });
        } else {
          const maxPageWillAvail =
            Math.floor(
              (commentListData?.page?.totalElements - 1) /
                commentListData?.page?.size
            ) +
            ((commentListData?.page?.totalElements - 1) %
              commentListData?.page?.size >
            0
              ? 1
              : 0);
          if (Number(commentListData?.page?.number) >= maxPageWillAvail) {
            setCommentQueryData({
              ...commentQueryData,
              page: maxPageWillAvail - 1,
              isFilter: true,
            });
          } else {
            fetchRecipeCommentList(getId, commentQueryData);
          }
          addToast({
            toast: `TOASTR.COMMON.COMMENT_DELETE_SUCCESS`,
            type: "success",
          });
        }
      })
      .catch((e) => {
        addToast({ toast: e });
      })
      .finally(() => setLoader(false));
  };

  const deleteSubstitution = (id) => {
    setLoader(true);
    CommentService.deleteSubstitution(id)
      .then((subsDeleteResponse) => {
        if (parseInt(subsDeleteResponse.status, 0) === 204) {
          addToast({ toast: `COMMON.NO_DATA_FOUND` });
        } else {
          const maxPageWillAvail =
            Math.floor(
              (substitutionData?.page?.totalElements - 1) /
                substitutionData?.page?.size
            ) +
            ((substitutionData?.page?.totalElements - 1) %
              substitutionData?.page?.size >
            0
              ? 1
              : 0);
          if (Number(substitutionData?.page?.number) >= maxPageWillAvail) {
            setSubsQueryData({
              ...subsQueryData,
              page: maxPageWillAvail - 1,
              isFilter: true,
            });
          } else {
            fetchRecipeSubstitutionData(getId, subsQueryData);
          }
          addToast({
            toast: `TOASTR.COMMON.SUBSTITUTION_DELETE_SUCCESS`,
            type: "success",
          });
        }
      })
      .catch((e) => {
        addToast({ toast: e });
      })
      .finally(() => setLoader(false));
  };

  const updateSubstitution = (data = {}) => {
    setLoader(true);
    CommentService.updateRecipeBlock(data?.id, "SUBSTITUTION", !data?.isBlocked)
      .then((res) => {
        if (res.status === 204) {
          return addToast({ toast: "Substitution Not Found!", type: "error" });
        }
        addToast({
          toast: "Substitutions updated successfully!",
          type: "success",
        });
        setSubsQueryData({
          ...subsQueryData,
          isFilter: true,
        });
      })
      .catch((e) => {
        addToast({ toast: e });
      })
      .finally(() => setLoader(false));
  };

  const updateComment = (data = {}) => {
    setLoader(true);
    CommentService.updateRecipeBlock(data?.id, "COMMENT", !data?.isBlocked)
      .then((res) => {
        if (res.status === 204) {
          return addToast({ toast: "Comment Not Found!", type: "error" });
        }
        addToast({
          toast: "Comment updated successfully!",
          type: "success",
        });
        setCommentQueryData({
          ...commentQueryData,
          isFilter: true,
        });
      })
      .catch((e) => {
        addToast({ toast: e });
      })
      .finally(() => setLoader(false));
  };

  useEffect(() => {
    fetchRecipe(id);
  }, []);

  useEffect(() => {
    if (commentQueryData?.isFilter && tabIndex === 0) {
      setCommentQueryData({ ...commentQueryData, isFilter: false });
      fetchRecipeCommentList(id, commentQueryData);
    }
  }, [commentQueryData]);

  useEffect(() => {
    if (subsQueryData?.isFilter && tabIndex === 1) {
      setSubstitutionData({ ...subsQueryData, isFilter: false });
      fetchRecipeSubstitutionData(id, subsQueryData);
    }
  }, [subsQueryData]);

  return (
    <RecipeInfoPresentational
      recipeData={recipeData}
      commentListData={commentListData}
      substitutionData={substitutionData}
      isCommentListEmpty={isCommentListEmpty}
      issubstitutionEmpty={issubstitutionEmpty}
      commentQueryData={commentQueryData}
      setCommentQueryData={setCommentQueryData}
      subsQueryData={subsQueryData}
      setSubsQueryData={setSubsQueryData}
      deleteComment={deleteComment}
      deleteSubstitution={deleteSubstitution}
      updateSubstitution={updateSubstitution}
      updateComment={updateComment}
      tabIndex={tabIndex}
      setTabIndex={setTabIndex}
      isDescriptionModelOpen={isDescriptionModelOpen}
      setIsDescriptionModelOpen={setIsDescriptionModelOpen}
    />
  );
};
export default RecipeInfoFunctional;
