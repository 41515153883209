import axios from "axios";
import { RecipeUrl } from "./url";

const getRecipeCommentList = (id, params = {}) => {
  return axios.get(RecipeUrl.Base + `/${id}/comment-admin`, { params });
};

const deleteComment = (id) => {
  return axios.delete(RecipeUrl.Base + `/comment/${id}`);
};
const deleteSubstitution = (id) => {
  return axios.delete(RecipeUrl.Base + `/substitution/${id}`);
};

const getSubstitutionData = (id, params = {}) => {
  return axios.get(RecipeUrl.Base + `/recipe/${id}/substitution-admin`, {
    params,
  });
};
const updateRecipeBlock = (id, type, isBlocked) => {
  return axios.patch(
    RecipeUrl.Base + `/recipe/block/${id}?type=${type}&isBlocked=${isBlocked}`
  );
};

const CommentService = {
  getRecipeCommentList,
  deleteComment,
  deleteSubstitution,
  updateRecipeBlock,
  getSubstitutionData,
};

export default CommentService;
