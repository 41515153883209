import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import validator from "../../../@shared/validators/validator";
import FormErrMsg from "../../../@shared/component/form-err-msg/form-err-msg";
import TextField from "../../../@shared/component/text-field";
import LgoinBg from "../../../assets/image/login-bg.png";
import Logo from "../../../assets/image/qolleqt-logo.svg";

const ForgetPasswordPresentational = ({
  sendEmailClicked,
  email,
  setEmail,
  btnLoading,
  backToLogin,
}) => {
  const { t } = useTranslation();

  const { control, trigger, formState: { errors } } = useForm({
    defaultValues: { userName: null },
    mode: "all"
  });

  const validatormsg = {
    email: {
      required: { value: true, message: "VALIDATOR.REQUIRED" },
      pattern: { value: validator.EMAIL, message: "VALIDATOR.PATTERN.EMAIL" }
    },
  };

  return (
    <div className="flex items-center justify-center h-full bg-gray-200 md:h-screen" style={{ backgroundImage: `url(${LgoinBg})`}}>
      <div className="w-full max-w-sm md:max-w-xl bg-white border-b border-gray-400 drop-shadow-login-modal rounded-xl">
        <div className="px-11 py-10 md:px-20 md:py-12">
          <img className="m-auto text-center mb-7" src={Logo} alt={"Site Logo"} />
          <h2 className="text-3xl font-semibold leading-6 text-secondary uppercase text-center m-auto mb-8">{t("FORGOT_PAGE.COMMON.FORGOT_PASSWORD")}</h2>
          <p className="text-sm text-center m-auto mb-6">{t("FORGOT_PAGE.COMMON.INSTRUCTIONS")}</p>
          <div className="forget-password-form">
            <div className="mb-6">
              <Controller
                control={control}
                name="userName"
                rules={validatormsg?.email}
                render={({ field }) => (
                  <TextField
                    tabIndex="1"
                    {...field}
                    name="userName"
                    label={t("COMMON.USER_NAME")}
                    labelPostion={true}
                    labelClassName="form-label tracking-wide text-gray-700"
                    type="email"
                    value={email}
                    onInput={(e) => {
                      setEmail(e.target.value);
                      trigger("userName"); 
                    }}
                  />
                )}
              />
              <FormErrMsg errors={errors?.userName} />
            </div>
            <div className="text-center mb-6">
              <button
                onClick={() => {
                  trigger().then((res) => {
                    if (res) {
                      sendEmailClicked();
                    }
                  });
                }}
                className={`inline-flex justify-center mx-5 rounded-md border-transparent bg-gradient-primary px-14 py-2 text-lg text-white focus:outline-none disabled:opacity-25 disabled:cursor-not-allowed`}
                disabled={
                  btnLoading ||
                  email === null ||
                  email === ""
                }
              >
                {t("FORGOT_PAGE.COMMON.SEND_EMAIL")}
              </button>
            </div>
            <div className="text-center">
              <button className="text-lg text-gray-500" onClick={backToLogin}>{t("FORGOT_PAGE.COMMON.BACK_TO_LOGIN")}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPasswordPresentational;