import React from 'react';
import { Tab } from '@headlessui/react'

const Tabs = ({
  tabsData,
  defaultIndex = 0,
  selectedIndex,
  setSelectedIndex
}) => {
  return (
    <Tab.Group defaultIndex={defaultIndex} selectedIndex={selectedIndex} onChange={setSelectedIndex}>
      <div className="mb-5 border-b border-gray-200">
        <Tab.List as="ul" className="flex flex-wrap -mb-px text-sm font-medium text-center">
          {tabsData?.map((tabData, key) => {
            return (
              <Tab as="li" key={key} className="inline-block p-4 rounded-t-lg border-b-2 border-transparent outline-none cursor-pointer hover:text-gray-600 hover:border-gray-300 aria-selected:border-primary" aria-selected>{tabData?.tabTitle}</Tab>
            )
          })}
        </Tab.List>
      </div>
      <Tab.Panels>
        {tabsData?.map((tabData, key) => {
          return (
            <Tab.Panel key={key}>{tabData?.tabContent}</Tab.Panel>
          )
        })}
      </Tab.Panels>
    </Tab.Group>
  );
}

export default Tabs;