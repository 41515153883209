import React, { forwardRef } from "react";
import Label from "../label";
// import { Input } from "@material-tailwind/react";

function SimpleTextArea({ desc = null, ...restProps }) {
  return (
    <>
      <textarea {...restProps} />
      {desc && (
        <span className="block text-xs text-gray-400 italic text-light">
          {desc}
        </span>
      )}
    </>
  );
}

const TextAreaField = forwardRef(
  (
    {
      placeholder = "Type note here",
      value,
      labelStyle,
      className = "form-control",
      style,
      label = null,
      labelClassName = "",
      isTooltipContent = null,
      isTooltipIcon = null,
      name,
      border,
      isNext,
      min,
      max,
      allowClear = false,
      isRequiredClass = false,
      maxLength,
      rows = 3,
      desc = null,
      labelPostion = false,
      ...rest
    },
    ref
  ) => {
    let defaultInputClass =
      "border w-full border-gray-500 rounded-md focus:ring-gray-500 focus:border-gray-500";

    return (
      <div className={`w-full mb-6 md:mb-0${labelPostion ? " relative" : ""}`}>
        {label && (
          <Label
            style={labelStyle}
            className={labelClassName}
            title={label}
            isTooltipIcon={isTooltipIcon}
            isTooltipContent={isTooltipContent}
            isRequiredClass={isRequiredClass}
            labelPostion={labelPostion}
          />
        )}
        <SimpleTextArea
          {...rest}
          style={style}
          ref={ref}
          value={value}
          name={name}
          placeholder={placeholder}
          className={defaultInputClass}
          rows={rows}
        />
      </div>
    );
  }
);

export default TextAreaField;
