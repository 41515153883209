import { Provider } from "react-redux";
import RootStore from "@shared/store/create-store";
import { ToastContextProvider } from "@shared/contexts/ToastContext";
import { LoaderContextProvider } from "@shared/contexts/LoaderContext";
import Routes from "./routes";
import axios from "axios";

const App = () => {
  axios.defaults.withCredentials = true;
  return (
    <Provider store={RootStore}>
      <LoaderContextProvider>
        <ToastContextProvider>
          <Routes />
        </ToastContextProvider>
      </LoaderContextProvider>
    </Provider>
  );
};

export default App;
