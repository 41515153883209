import { Fragment, useState, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Dialog, Transition } from "@headlessui/react";
import FormErrMsg from "@shared/component/form-err-msg/form-err-msg";
import { PencilIcon, EnvelopeIcon } from "@heroicons/react/24/outline";
import UserAvatar from "assets/image/profile.png";
import TextField from "@shared/component/text-field";
import ResetPasswordPopup from "../reset-password-modal/reset-password-functional";

const MyprofilePresentational = ({
  currentUserData,
  updateUser,
  myprofileForm,
  setMyprofileForm,
  btnLoading = false,
  imageUpload,
  setIsOpen,
  isOpen,
}) => {
  const dispatch = useDispatch();
  dispatch({ type: "SET_PAGENAME", payload: "My Profile" });
  const imgUpload = useRef(null);
  const { t } = useTranslation();
  const {
    control,
    trigger,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      firstName: currentUserData?.name?.firstName,
      lastName: currentUserData?.name?.lastName,
    },
    mode: "all",
  });

  const onFormSubmit = () => {
    trigger().then((res) => {
      if (res) {
        const values = getValues();
        updateUser(values);
      }
    });
  };

  const validatormsg = {
    firstName: {
      required: { value: true, message: "VALIDATOR.REQUIRED" },
    },
    lastName: {
      required: { value: true, message: "VALIDATOR.REQUIRED" },
    },
  };

  return (
    <div>
      <div className="grid md:grid-cols-2 gap-4 m-12 p-12 bg-white">
        <div className="relative grid md:grid-cols-3 items-center justify-center mr-4 border-r-2 border-black-400">
          <div className="col-span-1">
            <input
              type="file"
              ref={imgUpload}
              className="hidden"
              onChange={(e) => imageUpload(e)}
            />
            <img
              onClick={() => imgUpload.current.click()}
              className="rounded-full w-32 h-32"
              src={currentUserData?.profileImage?.url || UserAvatar}
              alt=""
            />
          </div>
          <div className="col-span-2">
            <h4 className="text-xl font-medium mt-2 mb-2 text-gray-700">
              {currentUserData?.name &&
                Object?.values(currentUserData?.name)?.join(" ")?.trim()}
            </h4>
            <div className="flex items-center text-gray-500 mb-2 text-md">
              <EnvelopeIcon className="w-5 h-5 rounded-full bg-gray-500 text-white p-1" />
              <span className="ml-1">{currentUserData?.email}</span>
            </div>
          </div>
          <button
            className="absolute top-0 right-10 rounded-full text-white bg-gradient-to-br from-secondary to-primary p-1"
            type="button"
            onClick={() => {
              setIsOpen(true);
            }}
          >
            <PencilIcon className="w-3 h-3" />
          </button>
          {/* Strat Popup Form */}
          <Transition appear show={isOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-10"
              onClose={() => setIsOpen(false)}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex items-center justify-center min-h-full p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full max-w-2xl p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                      <Dialog.Title
                        as="h2"
                        className="text-lg font-medium leading-6 text-gray-900 border-b pb-2"
                      >
                        Myprofile
                      </Dialog.Title>

                      <div className="my-5">
                        <div class="columns-2">
                          <div className="mb-2">
                            <Controller
                              control={control}
                              name="firstName"
                              rules={validatormsg?.firstName}
                              render={({ field, value }) => (
                                <TextField
                                  tabIndex="1"
                                  {...field}
                                  name="firstName"
                                  label="First Name"
                                  placeholder="First Name"
                                  labelPostion={true}
                                  labelClassName="form-label tracking-wide text-gray-700"
                                  type="text"
                                  value={getValues("firstName")}
                                  onInput={(e) => {
                                    setValue("firstName", e.target.value);
                                    trigger("firstName");
                                  }}
                                />
                              )}
                            />
                            <FormErrMsg errors={errors?.firstName} />
                          </div>
                          <div className="mb-2">
                            <Controller
                              control={control}
                              name="lastName"
                              rules={validatormsg?.lastName}
                              render={({ field, value }) => (
                                <TextField
                                  tabIndex="1"
                                  {...field}
                                  name="lastName"
                                  label="Last Name"
                                  placeholder="Last Name"
                                  labelPostion={true}
                                  labelClassName="form-label tracking-wide text-gray-700"
                                  type="text"
                                  value={getValues("lastName")}
                                  onInput={(e) => {
                                    setValue("lastName", e.target.value);
                                    trigger("lastName");
                                  }}
                                />
                              )}
                            />
                            <FormErrMsg errors={errors?.lastName} />
                          </div>
                        </div>
                      </div>

                      <div className="border-t pt-5">
                        <span
                          className="text-white bg-gradient-to-br from-secondary to-primary rounded-md px-10 py-2 items-center justify-center mr-4"
                          onClick={() => {
                            reset();
                            setIsOpen(false);
                          }}
                        >
                          Cancel
                        </span>
                        <button
                          className="text-white bg-gradient-to-br from-secondary to-primary rounded-md px-10 py-1 items-center justify-center"
                          disabled={btnLoading}
                          onClick={() => onFormSubmit()}
                        >
                          Update
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
          {/* End Popup Form */}
        </div>
        <div className="grid md:grid-cols-1 items-center justify-center">
          <ResetPasswordPopup onClickConfirm={() => { }} />
        </div>
      </div>
    </div>
  );
};

export default MyprofilePresentational;
