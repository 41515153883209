import axios from "axios";
import { RecipeUrl } from "./url";

const getRecipeList = (params = {}) => {
  return axios.get(RecipeUrl.RecipeAdmin, { params });
};

const getRecipe = (id) => {
  return axios.get(RecipeUrl.Recipe + `/${id}`);
};

const updateRecipe = (id, body) => {
  return axios.put(RecipeUrl.Recipe + `/${id}`, body);
};

const deleteRecipe = (id) => {
  return axios.delete(RecipeUrl.Recipe + `/${id}`);
};

const updateRecipeStatus = (id, status) => {
  let body = {
    updateType: "RECIPE_STATUS",
    state: status,
    shelveType: null,
  };
  return axios.patch(RecipeUrl.Recipe + `/${id}`, body);
};

const updateRecipeShelveType = (id, shelveType) => {
  let body = {
    shelveType: shelveType,
  };
  return axios.patch(RecipeUrl.Recipe + `/${id}/SHELVE_TYPE`, body);
};
const getRecipeAnalytics = (id) => {
  // return axios.get(UserUrl.USER_ANALYTICS + `/${id}`);
  return axios.get(RecipeUrl.RecipeAnalytics + `/${id}/post-analytics`);
};

const gettaglist = () => {
  // return axios.get(UserUrl.USER_ANALYTICS + `/${id}`);
  return axios.get(RecipeUrl.Base + "/tag");
};

const RecipeService = {
  getRecipeList,
  getRecipe,
  updateRecipe,
  deleteRecipe,
  updateRecipeStatus,
  updateRecipeShelveType,
  getRecipeAnalytics,
  gettaglist,
};

export default RecipeService;
