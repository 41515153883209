import axios from "axios";
import { UserUrl } from "./url";

const currentUser = () => {
  return axios.get(UserUrl.CURRENT_USER);
};

const getUserList = (params = {}) => {
  return axios.get(UserUrl.USER, { params });
};

const getUser = (id) => {
  return axios.get(UserUrl.USER + `/${id}`);
};

const updateUser = (id, body) => {
  return axios.put(UserUrl.USER + `/${id}`, body);
};

const deleteUser = (id) => {
  let params = {
    isDeleteAll: false,
  };
  return axios.delete(UserUrl.USER + `/${id}`, { params });
};

const updateUserStatus = (id, status) => {
  let body = {
    // userId: id,
    userState: status,
    userUpdateState: "USER_UPDATE",
    isNotificationEnabled: null,
    privacySetting: null,
  };
  // return axios.patch(UserUrl.USER_STATE, body);
  return axios.patch(UserUrl.USER + `/${id}`, body);
};

const getCurrentUserDashboard = (params = { chartFilterType: "TODAY" }) => {
  return axios.get(UserUrl.USER_DASHBOARD, { params });
};

const getUserAnalytics = (id) => {
  // return axios.get(UserUrl.USER_ANALYTICS + `/${id}`);
  return axios.get(UserUrl.USER + `/${id}/post-analytics`);
};

const uploadImage = (domain, body) => {
  return axios.post(UserUrl.ATTACHMENT + "/" + domain, body);
};

const emailVerification = (params) => {
  return axios.get(UserUrl.CONFIRM_EMAIL, { params });
};

const getNotificationCount = (params) => {
  return axios.get(UserUrl.NOTIFICATION_COUNT, { params });
};

const UserService = {
  currentUser,
  getUserList,
  getUser,
  updateUser,
  deleteUser,
  updateUserStatus,
  getCurrentUserDashboard,
  getUserAnalytics,
  uploadImage,
  emailVerification,
  getNotificationCount,
};

export default UserService;
