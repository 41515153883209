import React, { useRef } from 'react';

import SideMenu from "../admin-layout/sidemenu";
import NavBar from "../admin-layout/navbar";
import Footer from "../admin-layout/footer";

const Layout = ({
  component: Component,
  exact = true,
  ...rest
}) => {
  const contentRef = useRef();

  const scrollToTop = (e) => {
    e.preventDefault();
    contentRef.current.scrollTop = 0;
  }

  return (
    <div className="grid grid-cols-6 md:grid-cols-10 gap-0 h-full">
      <div className="col-span-1 md:col-span-1 lg:col-span-2">
        <SideMenu />
      </div>
      <div className="col-span-5 md:col-span-9 lg:col-span-8 h-full">
        <div className="relative">
          <NavBar />
          <div className="overflow-hidden h-screen flex flex-col" style={{ paddingTop: 72 }}>
            <div ref={contentRef} className="flex flex-col flex-1 overflow-y-auto">
              <div className="flex flex-col flex-shrink-0 grow p-5 bg-gray-100">
                <Component exact={exact} {...rest} />
              </div>
              {/* <Footer onScrollTop={scrollToTop} /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Layout;
