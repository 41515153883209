import React, { useCallback, useContext, useState, createContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

const ToastContext = createContext();

export default ToastContext;

const TypeToast = [
  {
    title: 'success',
    toasterClasses: 'bg-green-200 text-green-600',
    dismissButtonClasses: 'hover:bg-green-500 hover:text-white focus:ring-green-300'
  },
  {
    title: 'error',
    toasterClasses: 'bg-red-100 text-red-500',
    dismissButtonClasses: 'hover:bg-red-500 hover:text-white focus:ring-red-300'
  },
  { title: 'info',
    toasterClasses: 'bg-blue-200 text-blue-600',
    dismissButtonClasses: 'hover:bg-blue-500 hover:text-white focus:ring-blue-300'
  },
  {
    title: 'warning',
    toasterClasses: 'bg-yellow-100 text-yellow-500',
    dismissButtonClasses: 'hover:bg-yellow-500 hover:text-white focus:ring-yellow-300'
  }
]

export const ToastContextProvider = ({ children, autoDelete = true, autoDeleteTime = 5000 }) => {
  const [toasts, setToasts] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const interval = setInterval(() => {
      if (autoDelete && toasts.length) {
        deleteToast(toasts[0].id);
      }
    }, autoDeleteTime);

    return () => {
      clearInterval(interval);
    }
    // eslint-disable-next-line
  }, [toasts, autoDelete, autoDeleteTime]);

  const deleteToast = id => {
    const toastListItem = toasts.findIndex(e => e.id === id);
    toasts.splice(toastListItem, 1);
    setToasts([...toasts]);
  }

  const addToast = useCallback(
    function ({toast, type= 'error'}) {
      if( toast ) {
        let re = TypeToast.find(t => t.title === type);
        const id = Math.floor((Math.random() * 101) + 1);
        let design = { id: id, description: toast };
        let leToast = { ...re, ...design };
        setToasts((toasts) => [...toasts, leToast]);
      }
    },
    [setToasts]
  );

  return (
    <ToastContext.Provider value={addToast}>
      {children}
      <div className={`notification-container bottom-0 right-0 fixed z-50 w-full md:max-w-sm max-h-screen overflow-hidden${toasts?.length > 0 ? '' : ' h-0 w-0'}`}>
        <div className={`notification-container-inner p-4 md:p-4`}>
          {
            [...toasts]?.map((toast) =>
              <div
                key={toast?.id}
                className={`flex items-center p-4 mb-4 w-full max-w-xs rounded-lg shadow ${toast?.toasterClasses}`}
                role="alert"
              >
                <div className="ml-3 text-sm font-normal pointer-events-none">{t(`${toast?.description}`)}</div>
                <button
                  type="button"
                  className={`ml-auto -mx-1.5 -my-1.5 rounded-lg focus:ring-2 p-1.5 inline-flex h-8 w-8 text-red-700 hover:bg-red-500 hover:text-white focus:ring-red-300`}
                  onClick={() => deleteToast(toast?.id)}
                >
                  <span className="sr-only">Close</span>
                  <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                </button>
              </div>
            )
          }
        </div>
      </div>
    </ToastContext.Provider>
  );
}

export function useToastContext() {
  return useContext(ToastContext);
}
