import React, { useEffect, useState } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import i18n from "../../../@core/i18n/i18n";
import { useToastContext } from "../../../@shared/contexts/ToastContext";
import AuthService from "../../../service/auth-service";
import loginService from "../../../service/login-service";
import UserService from "../../../service/user-service";
import LoginPresentational from "./login-presentational";

const LoginFunctional = () => {
  let navigate = useNavigate();
  const addToast = useToastContext();

  const [loginForm, setLoginForm] = useState({
    userName: "",
    password: "",
  });

  const [btnLoading, setBtnLoading] = useState(false);
  useEffect(() => {
    i18n.changeLanguage("en");
    const CurrentUser = AuthService.getCurrentUser();
    if (CurrentUser) {
      navigate(CurrentUser?.menuPermissions?.[0]?.actionUrl || "/dashboard");
    }
  });

  const loginClicked = () => {
    let request = {
      password: btoa(loginForm.password) || null, // "MTIzNDU2", // 123456
      userName: loginForm.userName || null, // "admin@email.com"
    };
    setBtnLoading(true);
    loginService
      .login(request)
      .then((loginResponse) => {
        if (parseInt(loginResponse.status, 0) === 204) {
          addToast({ toast: `COMMON.NO_DATA_FOUND` });
          // } else if (loginResponse?.data?.accessToken) {
        } else if (loginResponse?.data) {
          // const accessToken = loginResponse?.data?.accessToken;
          // AuthService.setToken(accessToken);
          if (loginResponse?.data?.refreshToken) {
            AuthService.setRefreshToken(loginResponse?.data?.refreshToken);
          }

          if (loginResponse.data && loginResponse?.data) {
            AuthService.setCurrentUser(loginResponse?.data);
            navigate("/dashboard");
          }
        } else {
          addToast({
            toast: `TOASTR.API.${loginResponse?.data?.constraintViolations?.[0]?.message}`,
          });
        }
        setBtnLoading(false);
      })
      .catch((e) => {
        addToast({ toast: e });
        setBtnLoading(false);
        AuthService?.logout();
      });
  };

  const handlePassword = () => {
    const path = {
      pathname: "/forget-password",
    };
    if (loginForm.userName) {
      path["search"] = createSearchParams({
        email: loginForm.userName,
      }).toString();
    }
    navigate(path);
  };

  return (
    <LoginPresentational
      loginForm={loginForm}
      loginClicked={loginClicked}
      setLoginForm={setLoginForm}
      handlePassword={handlePassword}
      btnLoading={btnLoading}
    />
  );
};
export default LoginFunctional;
