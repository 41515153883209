import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon, } from "@heroicons/react/24/outline";

export default function Modal(props) {
  const {
    isOpen = false,
    onClose = () => { },
    modalTitleTag = "h2",
    modalTitleText = "",
    modalContent = "",
  } = props
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10 " onClose={() => onClose()}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/50 backdrop-blur-sm" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="relative w-full max-w-xl transform overflow-hidden rounded-2xl bg-white px-14 py-7 text-center align-middle shadow-xl transition-all xl:max-w-6xl">
                  <XMarkIcon className="absolute top-4 right-4 h-8 p-1 rounded-full bg-primary text-white cursor-pointer" onClick={() => onClose()} />
                  <Dialog.Title
                    as={modalTitleTag}
                    className="text-2xl px-3 font-medium leading-6 text-gray-500"
                  >
                    {modalTitleText}
                  </Dialog.Title>
                  {modalContent && (
                    <div className="p-8 mt-4 border-t border-gray-600 text-justify text-lg text-gray-500 max-h-80vh overflow-hidden overflow-y-auto">
                      {modalContent}
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}