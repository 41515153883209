import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import validator from "../../../@shared/validators/validator";
import FormErrMsg from "../../../@shared/component/form-err-msg/form-err-msg";
import TextField from "../../../@shared/component/text-field";
import PasswordField from "../../../@shared/component/password-field";
import LgoinBg from "../../../assets/image/login-bg.png";
import Logo from "../../../assets/image/qolleqt-logo.svg";

const LoginPresentational = ({
  loginClicked,
  handlePassword,
  loginForm,
  setLoginForm,
  btnLoading = false,
}) => {
  const { t } = useTranslation();

  const { control, trigger, formState: { errors } } = useForm({
    defaultValues: { userName: null, password: null },
    mode: "all"
  });

  const onSubmit = (e) => {
    e.preventDefault();
    onLogin();
  }

  const onLogin = () => {
    trigger().then((res) => {
      if (res) {
        loginClicked();
      }
    });
  }
  const validatormsg = {
    email: {
      required: { value: true, message: "VALIDATOR.REQUIRED" },
      pattern: { value: validator.EMAIL, message: "VALIDATOR.PATTERN.EMAIL" }
    },
    password: {
      required: { value: true, message: "VALIDATOR.REQUIRED"},
      minLength: { value: 6, message: "VALIDATOR.MINIMUM.6"}
    }
  };

  return (
    <div className="flex items-center justify-center h-full bg-gray-200 md:h-screen" style={{ backgroundImage: `url(${LgoinBg})`}}>
      <div className="w-full max-w-sm md:max-w-xl bg-white border-b border-gray-400 drop-shadow-login-modal rounded-xl">
        <div className="px-11 py-10 md:px-20 md:py-12">
          <img className="m-auto text-center mb-7" src={Logo} alt={"Site Logo"} />
          <h2 className="text-3xl font-semibold leading-6 text-secondary uppercase text-center m-auto mb-8">{t("LOGIN_PAGE.COMMON.LOGIN")}</h2>
          <form onSubmit={onSubmit}>
            <div className="mb-6">
              <Controller
                control={control}
                name="userName"
                rules={validatormsg?.email}
                render={({ field }) => (
                  <TextField
                    tabIndex="1"
                    {...field}
                    name="userName"
                    label={t("COMMON.USER_NAME")}
                    labelPostion={true}
                    labelClassName="form-label tracking-wide text-gray-700"
                    type="email"
                    value={loginForm?.userName}
                    onInput={(e) => {
                      setLoginForm({
                        ...loginForm,
                        userName: e.target.value
                      });
                      trigger("userName"); 
                    }}
                  />
                )}
              />
              <FormErrMsg errors={errors?.userName} />
            </div>
            <div className="mb-8">
              <Controller
                control={control}
                name="password"
                rules={validatormsg?.password}
                render={({ field }) => (
                  <PasswordField
                    tabIndex="2"
                    {...field}
                    label={t("COMMON.PASSWORD")}
                    labelPostion={true}
                    labelClassName="form-label tracking-wide text-gray-700"
                    name="password"
                    value={loginForm?.password}
                    onInput={(e) => {
                      setLoginForm({
                        ...loginForm,
                        password: e.target.value
                      });
                      trigger("password");
                    }}
                  />
                )}
              />
              <FormErrMsg errors={errors?.password} />
            </div>
            <div className="text-center mb-6">
              <button
                type="submit"
                className={`inline-flex justify-center mx-5 rounded-md border-transparent bg-gradient-primary px-14 py-2 text-lg text-white focus:outline-none disabled:opacity-25 disabled:cursor-not-allowed`}
                disabled={
                  btnLoading ||
                  loginForm.userName === null ||
                  loginForm.userName === "" ||
                  loginForm.password === "" ||
                  loginForm.password === null ||
                  Object.keys(errors).length > 0
                }
              >
                {t("LOGIN_PAGE.COMMON.PWA_LOGIN")}
              </button>
            </div>
            <div className="text-center">
              <button
                className="text-lg text-gray-500"
                onClick={(e) => {
                  e.preventDefault();
                  handlePassword();
                }}
              >
                {t("LOGIN_PAGE.COMMON.FORGOT_PASSWORD")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPresentational;